import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { url } from "../api/Url";
import axios from "axios";

const Cardb2b = ({ data }) => {
  const navigate = useNavigate();
  const handleClick = async (slug) => {
    localStorage.clear();
    console.log('Link was clicked!' + slug);
    axios
    .post(url + "/slug_id/" + slug)
    .then((res) => {
    // setSellerid(res.data.info.user_id);
     localStorage.setItem('seller_id', res.data.info.user_id);
     localStorage.setItem('seller_slug', res.data.info.slug);
     localStorage.setItem('seller_info', res.data.info);
     navigate(`${data.slug}`); // Redirects to the /about route

    })
    .catch(() => {
     // setCategoriesdata([]);
    });
  };


  return (
    <>
      <div className="w-full text-left md:w-[90%] h-[180px] bg-white shadow-md rounded-lg mt-5 flex flex-row p-4 pb-4 mb-4">
        <div className="w-3/4 px-0">
          <h3 className="text-xl font-semibold">{data.seller_name}</h3>
          <p className="text-gray-700">
            This is a highly trendy womens wear outlet
          </p>
          <p className="mb-0 text-gray-600">Retail and Bulk</p>
          <p className="text-gray-600">{data.total_products} Products</p>
          <div className="flex space-x-2">
            <Link
              onClick={() => handleClick(`${data.slug}`)} /* to={`${data.slug}`}  */
              className="text-blue-500 hover:underline"
            >
              Website
            </Link>
            <Link href="#" className="text-blue-500 hover:underline">
              App
            </Link>
          </div>
        </div>
        <div className="w-1/4 px-0 h-3/4">
          <img
            src={data.seller_profile}
            alt="Seller Profile"
            className="w-full h-full object-contain rounded"
          />
        </div>
      </div>
    </>
  );
};

export default Cardb2b;
