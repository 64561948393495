import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../components/template_one/redux/slice/authSlice';
import PostsList from './PostsList';

function ReduxDashboard() {
    const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user) {
    /*   fetch('http://localhost/api/dashboard.php', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}` // Assuming token-based authentication
        }
      })
        .then((response) => response.json())
        .then((data) => setData(data))
        .catch((error) => console.error('Error fetching dashboard data:', error)); */
    }
  }, [user]);

  if (!user) {
    return <p>Please log in to see the dashboard</p>;
  }
  const submitlogOut = () =>{
    dispatch(logout(user));
  }
  return (
    <div>
      <h1>Dashboard</h1>
      <pre>{user.data.user_id}</pre>
      <button onClick={submitlogOut}>Logout</button>
        <PostsList/>
   
    </div>
  );
}

export default ReduxDashboard;
