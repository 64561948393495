import React, { useEffect, useState } from "react";
import Categories from "../categaries_menu/CategoriesMenu";
import Brandcard from '../cards/Brandcard';
import { Link, useNavigate } from "react-router-dom";
import { url } from "../api/Url";
import axios from "axios";

const BrandsSection2 = ({data}) => {
  const [branddata, setBranddata] = useState([]);
  const navigate = useNavigate();

  const getBrd = async () => {
    const json = JSON.stringify({ seller_id: "85" });
    axios.post(url + '/app/v1/api/get_brand_data_product_web', json)
      .then((res) => {
        setBranddata(res.data.message.slice(0,8));
      })
      .catch(() => {
        setBranddata([]);
      });
  };

  useEffect(() => {
    getBrd();
  }, []);

  const handleBrandClick = (brand_name) => {
    navigate(`/allproducts?brand=${encodeURIComponent(brand_name)}`);
  };

  return (
    <div className="mt-10">
      <div className="flex justify-between items-center">
        <h1 className="text-left font-bold text-[22px] mb-2" style={{color:`${data?.component?.image_gallery?.color}`}}>Brands Near me</h1>
        <Link to='/AllBrands' className="text-blue-700">view all</Link>
      </div>
      <div className="flex flex-wrap justify-center md:justify-between mt-5">
        {branddata && branddata.map(user => (
          <div onClick={() => handleBrandClick(user.brand_name)} key={user.brand_id}>
            <Brandcard 
              brand_id={user.brand_id}
              brand_name={user.brand_name}
              brand_slug={user.brand_slug}
              brand_img={user.brand_img}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BrandsSection2;