import React, { useEffect, useState } from "react";
import Categories from "../categaries_menu/CategoriesMenu";
import Brandcard from '../cards/Brandcard'
import { Link } from "react-router-dom";
import { url } from "../api/Url";
import axios from "axios";


const Brandallcomponent = ({data}) => {
  const [branddata, setBranddata] = useState([]);
  const sellerid = localStorage.getItem('seller_id');
  const sellerslug = localStorage.getItem('seller_slug');
  const getBrd = async () => {
    const json = JSON.stringify({ seller_id: sellerid });
    axios.post(url + '/app/v1/api/get_brand_data_product_web', json)
      .then((res) => {
       // console.log('data brand' + JSON.stringify(res.data.message));
        setBranddata(res.data.message.slice(0,8))
       // setBranddata([])
      })
      .catch(() => {
        setBranddata([])
      });
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    getBrd();
  }, [])
 

  return (
    <div className="mt-10">

      <div className="flex flex-wrap justify-center md:justify-between mt-5">

        {branddata && branddata.map(user => (

          <Brandcard key={user.brand_id} className="" brand_id={user.brand_id}
          brand_name={user.brand_name}
          brand_slug={user.brand_slug}
          brand_img={user.brand_img}
          />
        ))}
      </div>
    </div>
  );
};

export default Brandallcomponent;
