import React, { useState } from 'react';
import { FaCheck } from 'react-icons/fa';

const FilterDropdown = ({ title, options, selectedOption, setSelectedOption }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mb-4">
    
      <button
        className="w-full text-left font-semibold border-b-2 pb-2 flex justify-between items-center"
        onClick={() => setIsOpen(!isOpen)}
      >
        {title}
        <span>{isOpen ? '-' : '+'}</span>
      </button>

      {isOpen && (
        <div className="mt-2 pl-4">
          {options.map((option, index) => (
            <label key={index} className="block mb-2 flex items-center">
              <div className="relative">
                <input
                  type="radio"
                  name={title}
                  value={option}
                  checked={selectedOption === option}
                  onChange={() => setSelectedOption(option)}
                  className="appearance-none w-4 h-4 bg-white border rounded-md mr-2 checked:bg-stone-800"
                />
             
                {selectedOption === option && (
                  <span className="absolute left-0 top-0 w-4 h-4 text-white flex items-center justify-center">
                  <FaCheck className='w-[10px]'/>
                  </span>
                )}
              </div>
              {option}
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

export default FilterDropdown;
