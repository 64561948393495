import React, { useState } from 'react';

const AnotherBannerForm2 = ({ bannerId, onSubmit }) => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    linkedin: '',
    category: '',
    subCategory: '',
    subSubCategory: '',
    brand: '',
    mixAndMatch: 'no',
    occasion: '',
    dateTime: '',
    address: '',
    budget: { min: '', max: '' },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <div className=" ">
      <form onSubmit={handleSubmit} className="space-y-4 ml-10">
        {bannerId === '7' && (
          <>
            <h2 className="text-2xl font-bold mb-4">Premium Gifting</h2>

            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="mt-1 block md:w-[600px] lg:w-[600px] shadow-sm border-black"
              />
            </div>

            <div>
              <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                Phone
              </label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="mt-1 block md:w-[600px] lg:w-[600px] rounded-md border-gray-300 shadow-sm"
              />
            </div>

            <div>
              <label htmlFor="linkedin" className="block text-sm font-medium text-gray-700">
                Linkedin
              </label>
              <input
                type="url"
                id="linkedin"
                name="linkedin"
                value={formData.linkedin}
                onChange={handleChange}
                className="mt-1 block md:w-[600px] lg:w-[600px] rounded-md border-gray-300 shadow-sm"
              />
            </div>

            <div>
              <label htmlFor="category" className="block text-sm font-medium text-gray-700">
                What category
              </label>
              <select
                id="category"
                name="category"
                value={formData.category}
                onChange={handleChange}
                className="mt-1 block md:w-[600px] lg:w-[600px] rounded-md border-gray-300 shadow-sm"
              >
                <option>Men</option>
                <option>Women</option>
                <option>Kids</option>
                <option>Home</option>
              </select>
            </div>

            <div>
              <label htmlFor="subCategory" className="block text-sm font-medium text-gray-700">
                What sub category
              </label>
              <input
                type="text"
                id="subCategory"
                name="subCategory"
                value={formData.subCategory}
                onChange={handleChange}
                className="mt-1 block md:w-[600px] lg:w-[600px] rounded-md border-gray-300 shadow-sm"
              />
            </div>

            <div>
              <label htmlFor="subSubCategory" className="block text-sm font-medium text-gray-700">
                Which sub sub category
              </label>
              <input
                type="text"
                id="subSubCategory"
                name="subSubCategory"
                value={formData.subSubCategory}
                onChange={handleChange}
                className="mt-1 block md:w-[600px] lg:w-[600px] rounded-md border-gray-300 shadow-sm"
              />
            </div>

            <div>
              <label htmlFor="brand" className="block text-sm font-medium text-gray-700">
                Which brand
              </label>
              <input
                type="text"
                id="brand"
                name="brand"
                value={formData.brand}
                onChange={handleChange}
                className="mt-1 block md:w-[600px] lg:w-[600px] rounded-md border-gray-300 shadow-sm"
              />
            </div>

            <div>
              <label htmlFor="mixAndMatch" className="block text-sm font-medium text-gray-700">
                Mix and match assortment
              </label>
              <select
                id="mixAndMatch"
                name="mixAndMatch"
                value={formData.mixAndMatch}
                onChange={handleChange}
                className="mt-1 block md:w-[600px] lg:w-[600px] rounded-md border-gray-300 shadow-sm"
              >
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>

            <div>
              <label htmlFor="occasion" className="block text-sm font-medium text-gray-700">
                Occasion
              </label>
              <select
                id="occasion"
                name="occasion"
                value={formData.occasion}
                onChange={handleChange}
                className="mt-1 block md:w-[600px] lg:w-[600px] rounded-md border-gray-300 shadow-sm"
              >
                <option>Birthday</option>
                <option>Anniversary</option>
                <option>Festive</option>
                <option>Corporate</option>
                <option>Others</option>
              </select>
            </div>

            <div>
              <label htmlFor="dateTime" className="block text-sm font-medium text-gray-700">
                Date & Time
              </label>
              <input
                type="datetime-local"
                id="dateTime"
                name="dateTime"
                value={formData.dateTime}
                onChange={handleChange}
                className="mt-1 block md:w-[600px] lg:w-[600px] rounded-md border-gray-300 shadow-sm"
              />
            </div>

            <div>
              <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                Address
              </label>
              <textarea
                id="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                className="mt-1 block md:w-[600px] lg:w-[600px] rounded-md border-gray-300 shadow-sm"
              />
            </div>

            <div>
              <label htmlFor="budgetMin" className="block text-sm font-medium text-gray-700">
                Budget (Min Rs)
              </label>
              <input
                type="number"
                id="budgetMin"
                name="budgetMin"
                value={formData.budget.min}
                onChange={(e) => handleChange(e)}
                className="mt-1 block md:w-[600px] lg:w-[600px] rounded-md border-gray-300 shadow-sm"
              />

              <label htmlFor="budgetMax" className="block text-sm font-medium text-gray-700">
                Budget (Max Rs)
              </label>
              <input
                type="number"
                id="budgetMax"
                name="budgetMax"
                value={formData.budget.max}
                onChange={(e) => handleChange(e)}
                className="mt-1 block md:w-[600px] lg:w-[600px] "
              />
            </div>
          </>
        )}

        <div>
          <button
            type="submit"
            className="md:w-[600px] lg:w-[600px] py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Submit and Proceed
          </button>
        </div>
      </form>
    </div>
  );
};

export default AnotherBannerForm2;
