import React, { useState } from 'react';

const CustomBannerForm = ({ bannerId, onSubmit, onClose }) => {
  const [formData, setFormData] = useState({
    productLink: '',
    productName: '',
    productPrice: '',
    quantity: 1,
    extraNotes: '',
    products: [{ category: '', subCategory: '', subSubCategory: '' }],
    inspiration: '',
    inspirationImage: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleProductChange = (index, field, value) => {
    const updatedProducts = [...formData.products];
    updatedProducts[index][field] = value;
    setFormData((prevState) => ({
      ...prevState,
      products: updatedProducts,
    }));
  };

  
  const handleAddProduct = () => {
    if (formData.products.length < 5) {
      setFormData((prevState) => ({
        ...prevState,
        products: [...prevState.products, { category: '', subCategory: '', subSubCategory: '' }],
      }));
    }
  };

  const handleRemoveProduct = (index) => {
    const updatedProducts = formData.products.filter((_, i) => i !== index);
    setFormData((prevState) => ({
      ...prevState,
      products: updatedProducts,
    }));
  };

  const handleImageChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      inspirationImage: e.target.files[0],
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4 ">
      <h2 className="text-2xl font-bold mb-4">Try at Home Fashion</h2>
      
      <div>
        <label htmlFor="productLink" className="block text-sm font-medium text-gray-700">Product web link</label>
        <input
          type="text"
          id="productLink"
          name="productLink"
          value={formData.productLink}
          onChange={handleChange}
          className="mt-1 block md:w-[600px] lg:w-[600px] rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          required
        />
    

      </div>

      <div>
        <label htmlFor="productName" className="block text-sm font-medium text-gray-700">Product Name</label>
        <input
          type="text"
          id="productName"
          name="productName"
          value={formData.productName}
          onChange={handleChange}
          className="mt-1 block md:w-[600px] lg:w-[600px] rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          required
        />
      </div>

      {/* <div className="flex space-x-4"> */}
        <div className="flex-1">
          <label htmlFor="productPrice" className="block text-sm font-medium text-gray-700">Product price</label>
          <input
            type="number"
            id="productPrice"
            name="productPrice"
            value={formData.productPrice}
            onChange={handleChange}
            className="mt-1 block md:w-[600px] lg:w-[600px] rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            required
          />
        </div>
        <div className="flex-1">
          <label htmlFor="quantity" className="block text-sm font-medium text-gray-700">Quantity</label>
          <input
            type="number"
            id="quantity"
            name="quantity"
            value={formData.quantity}
            onChange={handleChange}
            className="mt-1 block md:w-[600px] lg:w-[600px] rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            min="1"
            required
          />
        </div>
      {/* </div> */}

      <div>
        <label htmlFor="extraNotes" className="block text-sm font-medium text-gray-700">Extra notes for us</label>
        <textarea
          id="extraNotes"
          name="extraNotes"
          value={formData.extraNotes}
          onChange={handleChange}
          rows="3"
          className="mt-1 block md:w-[600px] lg:w-[600px] rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        ></textarea>
      </div>

      <div>
              <label htmlFor="inspiration" className="block text-sm font-medium text-gray-700">Inspiration</label>
              <textarea
                id="inspiration"
                name="inspiration"
                value={formData.inspiration}
                onChange={handleChange}
                className="mt-1 block md:w-[600px] lg:w-[600px] rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>

            <div>
              <label htmlFor="inspirationImage" className="block text-sm font-medium text-gray-700">Inspiration Image</label>
              <input
                type="file"
                id="inspirationImage"
                name="inspirationImage"
                onChange={handleImageChange}
                className="mt-1 block md:w-[600px] lg:w-[600px] rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>

      <div>
              <h3 className="text-lg font-bold">Add Products</h3>
              {formData.products.map((product, index) => (
                <div key={index} className="space-y-2">
                  <div>
                    <label htmlFor={`category-${index}`} className="block text-sm font-medium text-gray-700">Category</label>
                    <select
                      id={`category-${index}`}
                      name={`category-${index}`}
                      value={product.category}
                      onChange={(e) => handleProductChange(index, 'category', e.target.value)}
                      className="mt-1 block md:w-[600px] lg:w-[600px] rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    >
                      <option>Men</option>
                      <option>Women</option>
                      <option>Kids</option>
                      <option>Home</option>
                    </select>
                  </div>
                  <div>
                    <label htmlFor={`subCategory-${index}`} className="block text-sm font-medium text-gray-700">Sub Category</label>
                    <input
                      type="text"
                      id={`subCategory-${index}`}
                      name={`subCategory-${index}`}
                      value={product.subCategory}
                      onChange={(e) => handleProductChange(index, 'subCategory', e.target.value)}
                      className="mt-1 block md:w-[600px] lg:w-[600px] rounded-md border-gray-900 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                  <div>
                    <label htmlFor={`subSubCategory-${index}`} className="block text-sm font-medium text-gray-700">Sub Sub Category</label>
                    <input
                      type="text"
                      id={`subSubCategory-${index}`}
                      name={`subSubCategory-${index}`}
                      value={product.subSubCategory}
                      onChange={(e) => handleProductChange(index, 'subSubCategory', e.target.value)}
                      className="mt-1 block md:w-[600px] lg:w-[600px] rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                  {index > 0 && (
                    <button type="button" onClick={() => handleRemoveProduct(index)} className="text-red-500 text-sm">
                      Remove Product
                    </button>
                  )}
                </div>
              ))}
              {formData.products.length < 5 && (
                <button
                  type="button"
                  onClick={handleAddProduct}
                  className="mt-2 py-1 px-4 bg-black text-white rounded-md shadow-sm"
                >
                  Add Another Product
                </button>
              )}
            </div>

            

      <div>
        <button type="submit" className="md:w-[600px] lg:w-[600px] py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Submit Request & Proceed to Payment
        </button>
        {/* <button type="button" onClick={onClose} className="py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Close
        </button> */}
      </div>
    </form>
  );
};

export default CustomBannerForm;