import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { url } from "../../components/api/Url";
import Banner3 from "../../components/cards/Banner3";
import UserContext from "../../context/UserContext";
import CategoriesMenu2 from "../../components/categaries_menu/CategoriesMenu2";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Card6 from "../../components/cards/Card6";
import BrandsSection2 from "../../components/brands/BrandsSection2";
import Highlights2 from "../../components/highlights/Highlights2";
import Features from "../../components/features/Features";
import Blogs2 from "../../components/blogs/Blogs2";
import Testimonials2 from "../../components/testimonials/Testimonials2";
import Model3D2 from "../../components/model3D/Model3D2";
import Video from "../../components/model3D/Video";
import { Link } from "react-router-dom";
import LinkInput from "../zulu_club/LinkInput";
import NavigationBar from "./NavigationBar"; // Import the NavigationBar component
import CategoriesMenu3 from "../../components/categaries_menu/CategoriesMenu3";
import { CategoryProvider } from "../../components/categaries_menu/CategoryContext";
import Marquee from "../../components/cards/Marquee";
import OurApp from "../../components/logo_App/OurApp";


const SellerHome = () => {
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [sliderImages, setSliderImages] = useState([]);
  const [sliderImagesdata, setSliderImagesData] = useState([]);
  const [modelData, setModelData] = useState([]);
  const { setError, setLoader, loader, error } = useContext(UserContext);
  const navigate = useNavigate(); // For programmatic navigation
  const [selectedHighlightId, setSelectedHighlightId] = useState(null);

  const sellersUrl = "https://zulushop.in/app/v1/api/get_sellers";
  const categoriesUrl = "https://zulushop.in/zulu_category_list";
  const sliderUrl = "https://zulushop.in/app/v1/api/get_slider_backup2";
  const baseUrl = "https://zulushop.in/";


  const handleHighlightClick = (id) => {
    setSelectedHighlightId(id);
  };

  async function fetchData() {
    try {
      const response = await axios.post(sellersUrl);
      setData(response.data.data || []);
    } catch (error) {
      console.error("Error fetching sellers:", error);
      setError(true);
    }
  }

  async function fetchCategories() {
    try {
      const response = await axios.post(categoriesUrl);
      let res = response.data;

      if (typeof res === "string") {
        try {
          res = JSON.parse(res);
        } catch (parseError) {
          console.error("Error parsing JSON:", parseError);
          setCategories([]);
          return;
        }
      }

      if (Array.isArray(res.data)) {
        const categoryImages = res.data
          .map((item) => ({
            image: item.image,
            name: item.name,
          }))
          .slice(0, 4);

        setCategories(categoryImages);
      } else {
        console.warn("Unexpected data structure:", res);
        setCategories([]);
      }
    } catch (error) {
      setError(true);
      console.error("Error fetching categories:", error);
    }
  }

  async function fetchSliderData() {
    try {
      const response = await axios.post(sliderUrl);
      const res = response.data;

      if (Array.isArray(res)) {
        const bannerImages = res.map((item) => `${baseUrl}${item.image}`);
        setSliderImages(bannerImages);
        setSliderImagesData(res);
      } else {
        setSliderImages([]);
      }
    } catch (error) {
      setError(true);
      console.error("Error fetching slider images:", error);
    }
  }

  async function fetch3DModels() {
    try {
      const response = await axios.post(`${url}/get_all_videos`, {
        video_type: "3dModel",
      });
      setModelData(response.data.message || []);
    } catch (error) {
      console.error("Error fetching 3D models:", error);
      setError(true);
    }
  }

  useEffect(() => {
    const fetchAllData = async () => {
      setLoader(true);
      await Promise.all([
        fetchData(),
        fetchCategories(),
        fetchSliderData(),
        fetch3DModels(),
      ]);
      setLoader(false);
    };
    fetchAllData();
  }, [setLoader, setError]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  const sections = [
    { id: "categories", label: "Categories" },
    { id: "outlets", label: "Outlets Near Me" },
    { id: "brands", label: "Brands" },
    { id: "highlights", label: "Highlights" },
    { id: "features", label: "Features" },
    { id: "blogs", label: "Blogs" },
    { id: "testimonials", label: "Testimonials" },
    { id: "model3d", label: "3D Models" },
  ];

  return (
    <>

      {/* Navigation Bar */}
      {/* <NavigationBar sections={sections} /> */}


    {/* Categories Section */}

    <CategoryProvider>
    <section id="categories" className="mt-[-40px]">
        <CategoriesMenu3 data={data} />
      </section>

    <section id="categories" className="mt-10">
        <CategoriesMenu2 data={data} />
      </section>

    </CategoryProvider>

      {/* Slider */}
      {sliderImages.length > 0 ? (
        <Banner3 images={sliderImages} data={sliderImagesdata}/>
      ) : (
        <p>No images to display</p>
      )}
      
        {/* Outlets Near Me Section */}
        <section id="outlets" className="mt-20 mb-10">
        <div className="flex flex-row items-center justify-between">
          <h1 className="font-bold text-2xl">Outlets Near Me</h1>
          <div>
          <Link to="/AllOutlets" state={{ data, loader, error }} className="text-blue-700">
                View All
          </Link>

          </div>
        </div>

        <Slider {...settings}>
          {data.map((value) => (
            <div className="px-2" key={value.seller_id}>
              <Card6 data={value} />
            </div>
          ))}
        </Slider>
      </section>

     {/* Highlights Section */}
     <section id="highlights" className="mt-10">
     <Highlights2 data={data} onHighlightClick={handleHighlightClick} />
      </section>

          {/* Features Section */}
          <section id="features" className="mt-10">
          <Features data={data} selectedHighlightId={selectedHighlightId} />
      </section>

      
       {/* 3D Models Section */}
       <section id="model3d" className="mt-10">
        <Model3D2 data={data} modelData={modelData} />
        <Video data={data} modelData={modelData} />
      </section>

    

      {/* Brands Section */}
      <section id="brands" className="mt-10">
        <BrandsSection2 data={data} />
      </section>

 

          {/* 3D Models Section */}
          {/* <section id="model3d" className="mt-10">
        <Model3D2 data={data} modelData={modelData} />
        <Video data={data} modelData={modelData} />
      </section> */}


  
      {/* Blogs Section */}
      <section id="blogs" className="mt-10">
        <Blogs2 data={data} />
      </section>

      {/* Testimonials Section */}
      <section id="testimonials" className="mt-10">
        <Testimonials2 data={data} />
      </section>

      <OurApp />

  
    </>
  );
};

export default SellerHome;
