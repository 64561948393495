import React, { useState, useEffect } from "react";
import Logo from '../../assets/images/OurApp_Logo.webp'
import { FaAndroid, FaGooglePlay } from "react-icons/fa6";
import { FcTabletAndroid } from "react-icons/fc";

const OurApp = () => {

 
  return (
    <div className="mt-10">
      <div className="flex justify-center items-center">
        {/* <h1 className="text-right font-bold text-[22px]" style={{color:`${data?.component?.image_gallery?.color}`}}>{data?.component?.categorycard?.title}</h1> */}
        <img 
        src={Logo}
        alt="logo"
        className="w-[160px] h-[160px] rounded-lg"
        />
         <div className="justify-center ml-10 text-lg">
         <div className="text-left font-bold">
         Download Our App 
        </div>
        <div className="text-left my-2 flex items-center">
        <img className="w-6 h-6 mr-1" alt="svgImg" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTAiIGhlaWdodD0iNTAiIHZpZXdCb3g9IjAgMCA0OCA0OCI+CjxwYXRoIGZpbGw9IiM3Y2IzNDIiIGQ9Ik0xMiAyOWMwIDEuMS0uOSAyLTIgMnMtMi0uOS0yLTJ2LTljMC0xLjEuOS0yIDItMnMyIC45IDIgMlYyOXpNNDAgMjljMCAxLjEtLjkgMi0yIDJzLTItLjktMi0ydi05YzAtMS4xLjktMiAyLTJzMiAuOSAyIDJWMjl6TTIyIDQwYzAgMS4xLS45IDItMiAycy0yLS45LTItMnYtOWMwLTEuMS45LTIgMi0yczIgLjkgMiAyVjQwek0zMCA0MGMwIDEuMS0uOSAyLTIgMnMtMi0uOS0yLTJ2LTljMC0xLjEuOS0yIDItMnMyIC45IDIgMlY0MHoiPjwvcGF0aD48cGF0aCBmaWxsPSIjN2NiMzQyIiBkPSJNMTQgMTh2MTVjMCAxLjEuOSAyIDIgMmgxNmMxLjEgMCAyLS45IDItMlYxOEgxNHpNMjQgOGMtNiAwLTkuNyAzLjYtMTAgOGgyMEMzMy43IDExLjYgMzAgOCAyNCA4ek0yMCAxMy42Yy0uNiAwLTEtLjQtMS0xIDAtLjYuNC0xIDEtMXMxIC40IDEgMUMyMSAxMy4xIDIwLjYgMTMuNiAyMCAxMy42ek0yOCAxMy42Yy0uNiAwLTEtLjQtMS0xIDAtLjYuNC0xIDEtMXMxIC40IDEgMUMyOSAxMy4xIDI4LjYgMTMuNiAyOCAxMy42eiI+PC9wYXRoPjxwYXRoIGZpbGw9IiM3Y2IzNDIiIGQ9Ik0yOC4zIDEwLjVjLS4yIDAtLjQtLjEtLjYtLjItLjUtLjMtLjYtLjktLjMtMS40bDEuNy0yLjVjLjMtLjUuOS0uNiAxLjQtLjMuNS4zLjYuOS4zIDEuNGwtMS43IDIuNUMyOSAxMC4zIDI4LjcgMTAuNSAyOC4zIDEwLjV6TTE5LjMgMTAuMWMtLjMgMC0uNy0uMi0uOC0uNWwtMS4zLTIuMWMtLjMtLjUtLjItMS4xLjMtMS40LjUtLjMgMS4xLS4yIDEuNC4zbDEuMyAyLjFjLjMuNS4yIDEuMS0uMyAxLjRDMTkuNyAxMCAxOS41IDEwLjEgMTkuMyAxMC4xeiI+PC9wYXRoPgo8L3N2Zz4="/>
        Zulu Club - Consumer App
        </div>
        <div className="text-left my-2 flex items-center">
        <img className="w-6 h-6 mr-1" alt="svgImg" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTAiIGhlaWdodD0iNTAiIHZpZXdCb3g9IjAgMCA0OCA0OCI+CjxwYXRoIGZpbGw9IiM3Y2IzNDIiIGQ9Ik0xMiAyOWMwIDEuMS0uOSAyLTIgMnMtMi0uOS0yLTJ2LTljMC0xLjEuOS0yIDItMnMyIC45IDIgMlYyOXpNNDAgMjljMCAxLjEtLjkgMi0yIDJzLTItLjktMi0ydi05YzAtMS4xLjktMiAyLTJzMiAuOSAyIDJWMjl6TTIyIDQwYzAgMS4xLS45IDItMiAycy0yLS45LTItMnYtOWMwLTEuMS45LTIgMi0yczIgLjkgMiAyVjQwek0zMCA0MGMwIDEuMS0uOSAyLTIgMnMtMi0uOS0yLTJ2LTljMC0xLjEuOS0yIDItMnMyIC45IDIgMlY0MHoiPjwvcGF0aD48cGF0aCBmaWxsPSIjN2NiMzQyIiBkPSJNMTQgMTh2MTVjMCAxLjEuOSAyIDIgMmgxNmMxLjEgMCAyLS45IDItMlYxOEgxNHpNMjQgOGMtNiAwLTkuNyAzLjYtMTAgOGgyMEMzMy43IDExLjYgMzAgOCAyNCA4ek0yMCAxMy42Yy0uNiAwLTEtLjQtMS0xIDAtLjYuNC0xIDEtMXMxIC40IDEgMUMyMSAxMy4xIDIwLjYgMTMuNiAyMCAxMy42ek0yOCAxMy42Yy0uNiAwLTEtLjQtMS0xIDAtLjYuNC0xIDEtMXMxIC40IDEgMUMyOSAxMy4xIDI4LjYgMTMuNiAyOCAxMy42eiI+PC9wYXRoPjxwYXRoIGZpbGw9IiM3Y2IzNDIiIGQ9Ik0yOC4zIDEwLjVjLS4yIDAtLjQtLjEtLjYtLjItLjUtLjMtLjYtLjktLjMtMS40bDEuNy0yLjVjLjMtLjUuOS0uNiAxLjQtLjMuNS4zLjYuOS4zIDEuNGwtMS43IDIuNUMyOSAxMC4zIDI4LjcgMTAuNSAyOC4zIDEwLjV6TTE5LjMgMTAuMWMtLjMgMC0uNy0uMi0uOC0uNWwtMS4zLTIuMWMtLjMtLjUtLjItMS4xLjMtMS40LjUtLjMgMS4xLS4yIDEuNC4zbDEuMyAyLjFjLjMuNS4yIDEuMS0uMyAxLjRDMTkuNyAxMCAxOS41IDEwLjEgMTkuMyAxMC4xeiI+PC9wYXRoPgo8L3N2Zz4="/>
        Zulu Business - Business App
        </div>
        <div className="text-left my-2 flex items-center">
        <img className="w-6 h-6 mr-1" alt="svgImg" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTAiIGhlaWdodD0iNTAiIHZpZXdCb3g9IjAgMCA0OCA0OCI+CjxwYXRoIGZpbGw9IiM3Y2IzNDIiIGQ9Ik0xMiAyOWMwIDEuMS0uOSAyLTIgMnMtMi0uOS0yLTJ2LTljMC0xLjEuOS0yIDItMnMyIC45IDIgMlYyOXpNNDAgMjljMCAxLjEtLjkgMi0yIDJzLTItLjktMi0ydi05YzAtMS4xLjktMiAyLTJzMiAuOSAyIDJWMjl6TTIyIDQwYzAgMS4xLS45IDItMiAycy0yLS45LTItMnYtOWMwLTEuMS45LTIgMi0yczIgLjkgMiAyVjQwek0zMCA0MGMwIDEuMS0uOSAyLTIgMnMtMi0uOS0yLTJ2LTljMC0xLjEuOS0yIDItMnMyIC45IDIgMlY0MHoiPjwvcGF0aD48cGF0aCBmaWxsPSIjN2NiMzQyIiBkPSJNMTQgMTh2MTVjMCAxLjEuOSAyIDIgMmgxNmMxLjEgMCAyLS45IDItMlYxOEgxNHpNMjQgOGMtNiAwLTkuNyAzLjYtMTAgOGgyMEMzMy43IDExLjYgMzAgOCAyNCA4ek0yMCAxMy42Yy0uNiAwLTEtLjQtMS0xIDAtLjYuNC0xIDEtMXMxIC40IDEgMUMyMSAxMy4xIDIwLjYgMTMuNiAyMCAxMy42ek0yOCAxMy42Yy0uNiAwLTEtLjQtMS0xIDAtLjYuNC0xIDEtMXMxIC40IDEgMUMyOSAxMy4xIDI4LjYgMTMuNiAyOCAxMy42eiI+PC9wYXRoPjxwYXRoIGZpbGw9IiM3Y2IzNDIiIGQ9Ik0yOC4zIDEwLjVjLS4yIDAtLjQtLjEtLjYtLjItLjUtLjMtLjYtLjktLjMtMS40bDEuNy0yLjVjLjMtLjUuOS0uNiAxLjQtLjMuNS4zLjYuOS4zIDEuNGwtMS43IDIuNUMyOSAxMC4zIDI4LjcgMTAuNSAyOC4zIDEwLjV6TTE5LjMgMTAuMWMtLjMgMC0uNy0uMi0uOC0uNWwtMS4zLTIuMWMtLjMtLjUtLjItMS4xLjMtMS40LjUtLjMgMS4xLS4yIDEuNC4zbDEuMyAyLjFjLjMuNS4yIDEuMS0uMyAxLjRDMTkuNyAxMCAxOS41IDEwLjEgMTkuMyAxMC4xeiI+PC9wYXRoPgo8L3N2Zz4="/>
        Zulu Partner - Stylist & Riders
        </div>
      </div>
      </div>
     
      <div className="flex justify-center md:justify-between gap-12 mt-5">
     
    
      </div>
    </div>
  );
};

export default OurApp;
