import React from "react";
import { FaRegHeart } from "react-icons/fa6";
import { url } from "../api/Url";

const Card8 = ({ image, title, price, discount }) => {
  return (
    <div className="border lg:w-64 w-full md:w-64 rounded-lg overflow-hidden shadow-lg">
      <img
        src={`${url}/${image}`}
        alt={title}
        className="w-full h-48 object-cover"
      />
      <div className="p-4 bg-transparent">
        <div className="flex justify-between items-center mb-2">
          <h3 className="font-bold">
            {title}
          </h3>
          {/* <button className="text-gray-500 hover:text-red-500">
            <FaRegHeart />
          </button> */}
        </div>
        <div className="flex items-center justify-between">
          <span className="font-bold text-lg">
            ₹{discount || price}
          </span>
          {discount && (
            <div className="flex items-center">
              <span className="text-gray-500 line-through text-sm mr-2">
                ₹{price}
              </span>
              <span className="bg-green-500 text-white px-2 py-1 text-xs rounded">
                {Math.floor(((price - discount) / price) * 100)}% OFF
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Card8;
