import React from "react";
import { Link } from "react-router-dom";
import { url } from "../../components/api/Url";

const SellerHighlistCard = ({ id, image, title, price, discount, width }) => {
  const sellerslug = localStorage.getItem('seller_slug');
  return (
    <div className={`rounded-xl overflow-hidden bg-slate-200 w-[${width}] `}>
      {/* <div className="rounded-xl shadow-md overflow-hidden bg-slate-100 group h-[350px] w-1/5"> */}
      <Link to={`/o/${sellerslug}/${id}/featuresdata`}> 
      <img
        src={`${url}/${image}`}
        className="w-full h-[200px]"
        alt=""
      /></Link> 
      <p>{title}</p>
   {/*    <p>{discount}</p>
      <strike>{price}</strike> */}

    </div>
  );
};

export default SellerHighlistCard;
