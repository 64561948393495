import React, { useEffect, useState } from "react";
import { Blogcard } from "../cards/Blogcard";
import { Link } from "react-router-dom";
import { url } from "../api/Url";
import axios from "axios";
import Testimonialcard from "../cards/Testimonialcard";

const Testimonials = ({ data, sellerid }) => {
  const [test, setTest] = useState([]);

  const getTest = async () => {
    const json = JSON.stringify({ seller_id: sellerid });
    axios
      .post(url + "/app/v1/api/listoftestimonal", json)
      .then((res) => {
        setTest(res.data.slice(0, 4));
      })
      .catch(() => {
        setTest([]);
      });
  };

  useEffect(() => {
    getTest();
  }, []);

  return (
    <div className="mt-10">
      <div className="flex justify-between items-center">
        <h1
          className="text-left font-bold text-[22px]"
          style={{ color: `${data?.component?.image_gallery?.color}` }}
        >
          {data?.component?.testimonial?.title}
        </h1>
        <Link to="" className="text-blue-700">
          view all
        </Link>
      </div>
      
      {/* Scrollable layout for mobile */}
      <div className="flex gap-4 overflow-x-auto mt-5 md:gap-8 md:overflow-hidden">
        {test &&
          test.map((user) => (
            <div key={user.id} className="flex-shrink-0 w-[250px] md:w-auto">
              <Testimonialcard
                id={user.id}
                comments={user.comments}
                username={user.username}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default Testimonials;
