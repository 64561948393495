import React, { createContext, useContext, useState, useEffect, useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(() => {
    try {
      const savedCart = localStorage.getItem('cartItems');
      return savedCart ? JSON.parse(savedCart) : [];
    } catch (error) {
      console.error('Error loading cart from localStorage:', error);
      return [];
    }
  });

  useEffect(() => {
    try {
      localStorage.setItem('cartItems', JSON.stringify(cartItems));
    } catch (error) {
      console.error('Error saving cart to localStorage:', error);
    }
  }, [cartItems]);

  const addToCart = useCallback((product, size) => {
    setCartItems(prevItems => {
      const existingItem = prevItems.find(item => item.id === product.id && item.size === size);
      if (existingItem) {
        toast.info('Increased quantity in cart', { autoClose: 1000 });
        return prevItems.map(item =>
          item.id === product.id && item.size === size
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      } else {
        toast.success('Added to cart', { autoClose: 1000 });
        return [...prevItems, { ...product, size, quantity: 1 }];
      }
    });
  }, []);

  const removeFromCart = useCallback((id, size) => {
    setCartItems(prevItems => {
      const updatedItems = prevItems.map(item => {
        if (item.id === id && item.size === size) {
          const newQuantity = Math.max(0, item.quantity - 1);
          if (newQuantity < item.quantity) {
            toast.warn('Decreased quantity in cart', { autoClose: 1000 });
          }
          return { ...item, quantity: newQuantity };
        }
        return item;
      }).filter(item => item.quantity > 0);

      return updatedItems;
    });
  }, []);

  const deleteFromCart = useCallback((id, size) => {
    setCartItems(prevItems => {
      const updatedItems = prevItems.filter(item => !(item.id === id && item.size === size));
      if (updatedItems.length < prevItems.length) {
        toast.error('Item removed from cart', { autoClose: 1000 });
      }
      return updatedItems;
    });
  }, []);

  const contextValue = useMemo(() => ({
    cartItems,
    addToCart,
    removeFromCart,
    deleteFromCart,
  }), [cartItems, addToCart, removeFromCart, deleteFromCart]);

  return (
    <CartContext.Provider value={contextValue}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};