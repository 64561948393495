import React from "react";
import { url } from "../api/Url";

const Card2 = ({ zulu_category_name, seller_category_name, zulu_category_image, seller_category_image, zulu_category_id }) => {
  return (
    <div className="w-full flex justify-center mb-4">
      <div className="relative w-[120px] cursor-pointer rounded-t-lg overflow-hidden"> {/* Added overflow-hidden */}
        {
          seller_category_image ?
            <>
              <img src={`${seller_category_image}`} alt="" className="w-full h-[120px] object-cover rounded-lg bg-gray-200 mt-4" />
            </>
            :
            <>
              <img src={`${zulu_category_image}`} alt="" className="w-full h-[120px] object-cover rounded-lg bg-gray-200 mt-4" />
            </>
        }

        <div className="absolute bottom-0 w-full bg-black bg-opacity-60 text-white text-center py-1 rounded-b-lg"> 
          <p className="text-sm">
            {seller_category_name ? seller_category_name : zulu_category_name}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Card2;
