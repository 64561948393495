import React from "react";
import "../../../pages/zulu_club/Home.css";

import a1 from "../../../assets/images/image8.webp";
const Cont7 = () => {
  return (
    <div className="cont5  relative mt-20 mx-auto ">
      <img
        src={a1}
        alt="Description of the image"
        className="w-full sm:h-[50vh] md:h-[64vh] lg:h-[90vh] object-cover rounded-lg"
      />
      <div className="text-overlay3 text-white">
        <h1>
          WHY <br />
          CHOOSE <br />
          ZULU
        </h1>
      </div>
    </div>
  );
};

export default Cont7;
