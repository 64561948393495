import React from 'react'
import a1 from '../../../assets/images/ZULU_Nano (1).png'
const Acont5 = () => {
  return (
    <div className='container rounded-[20px] overflow-hidden mt-20'>
        {/* <img src={a1} alt="" /> */}
    </div>
  )
}

export default Acont5