import React from "react";
import "../../../assets/style/aboutus_page.css";
import logo from "../../../assets/images/zulu-logo-2.png";

const Acont1 = () => {
  return (
    <div>
      {/* second section */}
      <div className="Acont1 container mb-5 bg-cover bg-center mt-5 rounded-[20px]">
        <div className="bg-[#1f0a1d7f]  sm:h-[50vh] md:h-[64vh] lg:h-[100vh]">
          <div className="bg-[#1f0a1d7f] h-full">
            <div className="pt-0 lg:pt-12">
              <h1 className="text-3xl sm:text-4xl xl:text-5xl font-bold pb-5 mt-5">
                {/* Zulu is an <span className="highlight">exciting</span> tech
            platform. */}
                A new way of Fashion shopping

              </h1>
              <div className="text-lg font-normal flex flex-col gap-4 xl:gap-16  ">
                <p className="uppercase text-[22px] md:text-[25px] font-bold">We dont sell, <span className=" text-yellow-200">we curate</span></p>
                <div className="flex flex-col gap-2 md:gap-5 text-[17px] md:text-[22px]">
                  <p>Help you <span className="text-yellow-200 font-bold">try at home</span> with a stylist
                  </p>
                  <p>Take care of <span className="font-bold text-yellow-200">alterations</span> & customizations
                  </p>
                  <p>Arrange premium <span className="font-bold text-yellow-200">gifting</span> experiences
                  </p>
                  <p>Buy online or offline, desi or videsi
                  </p>
                  <p className="font-bold ">We are there for you, <span className="text-yellow-200">right next door</span> </p>
                </div>
                <div className="sm:mt-[-10px] md:mt-3 mb-5">
                  <button className="btn1 leading-6 md:text-3xl uppercase">Join the Wait list</button>
                </div>
              </div>

            </div>


            {/* <div className="flex justify-center ">
            <img
              onclick="scaleDiv(this)"
              className="my-5 cursor-pointer w-[180px] aspect-[1/1] text-center"
              src={logo}
              alt=""
            />
          </div> */}
            {/* <p className="text-xl mt-8 relative bottom-0 sm:bottom-[37px] md:bottom-[-81px] lg:bottom-[-247px] py-8 font-light px-2 leading-[30px] sm:leading-[27px] text-white  bg-[#1f0a1d95]"> 
            [#c9c3c9]
            Fashion is all about excitement. <br />Zulu was born to find & deliver that <span className="font-bold text-yellow-200">excitement</span> to you <span className="text-yellow-200 font-bold">in person</span>
            &nbsp; & online.
          </p> */}
            <div className="h-[10] w-full"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Acont1;
