import React, { useRef, useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles.css";
import { useNavigate } from "react-router-dom";

const Banner4 = ({ images, data }) => {
  const sliderRef = useRef(null);
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Update isMobile state on window resize
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Set up auto-slide functionality for mobile devices
  useEffect(() => {
    if (isMobile) {
      const interval = setInterval(() => {
        sliderRef.current.slickNext();
      }, 3000); // Change image every 3 seconds

      return () => clearInterval(interval); // Clear interval on component unmount
    }
  }, [isMobile]);

  const settings = {
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 0.5,
    autoplay: false, // Disable default autoplay to use custom logic
    autoplaySpeed: 2500,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleWheel = (e) => {
    if (Math.abs(e.deltaX) > Math.abs(e.deltaY)) {
      e.preventDefault();
      if (e.deltaX < 0) {
        sliderRef.current.slickPrev();
      } else {
        sliderRef.current.slickNext();
      }
    }
  };

  const handleImageClick = (id) => {
    console.log(id, "img_id");
    navigate(`/image/${id}`);
  };

  return (
    <div className="text-left font-bold text-[22px]">
      <div className="w-[70%] mx-auto" onWheel={handleWheel}>
        <Slider ref={sliderRef} {...settings}>
          {data.map((imageUrl, index) => (
            <div
              key={index}
              className="flex gap-[10px] px-[10px]"
              onClick={() => handleImageClick(imageUrl?.id)}
            >
              <img
                src={`https://zulushop.in/${imageUrl?.image}`}
                className="h-[130px] w-[130px] object-cover rounded-[20px]"
                alt={`Slide ${index + 1}`}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Banner4;
