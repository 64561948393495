import React from "react";
import { Link } from "react-router-dom";
import { url } from "../../components/api/Url";

const SellerFeatureCard = ({ productid, image, title, price, discount, width, slugprd }) => {
  const sellerid = localStorage.getItem('seller_id');
  const sellerslug = localStorage.getItem('seller_slug');
  return (
    <div className={`rounded-xl overflow-hidden bg-slate-200 w-[${width}] `}>
      {/* <div className="rounded-xl shadow-md overflow-hidden bg-slate-100 group h-[350px] w-1/5"> */}
      <Link to={`/o/${sellerslug}/${productid}/${slugprd}/product`}>
      <img
        src={`${url}/${image}`}
        className="w-full h-[300px]"
        alt=""
      />
      <p>{title}</p>
      <p>{discount}</p>
      <strike>{price}</strike>
     {/*  <Link to="/productpage ">
        <button className="bg-[#303030ea] w-full py-3 text-white group-hover:-translate-y-12 translate-y-12 transition-all duration-300">
          Shop now
        </button>
      </Link> */}
      </Link>
    </div>
  );
};

export default SellerFeatureCard;
