// src/components/OrderCard.js
import React from 'react';

const OrderCard = ({ name, orderNumber, date, amount }) => {
  return (
    <div className="bg-white p-4 rounded-lg shadow-md w-40 h-20 ">
      <p className="text-xs font-semibold mb-2"> {name}</p>
    </div>
  );
};

export default OrderCard;
