import React, { useState } from 'react';

const AnotherBannerForm3 = ({ bannerId, onSubmit, onClose }) => {
  const [formData, setFormData] = useState({
    whereFrom: 'Anywhere',
    brands: [],
    totalBudget: { min: '', max: '' },
    products: [{ category: '', subCategory: '', subSubCategory: '', alteration: '' }],
    inspiration: '',
    inspirationImage: null,
    budgetRange: { min: '', max: '' },
    dateTime: '',
    duration: '',
    noOfClothes: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleProductChange = (index, field, value) => {
    const updatedProducts = [...formData.products];
    updatedProducts[index][field] = value;
    setFormData((prevState) => ({
      ...prevState,
      products: updatedProducts,
    }));
  };

  const handleAddProduct = () => {
    if (formData.products.length < 5) {
      setFormData((prevState) => ({
        ...prevState,
        products: [...prevState.products, { category: '', subCategory: '', subSubCategory: '', alteration: '' }],
      }));
    }
  };

  const handleRemoveProduct = (index) => {
    const updatedProducts = formData.products.filter((_, i) => i !== index);
    setFormData((prevState) => ({
      ...prevState,
      products: updatedProducts,
    }));
  };

  const handleImageChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      inspirationImage: e.target.files[0],
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <div className="">
      <form onSubmit={handleSubmit} className="space-y-4">
        {bannerId === '8' && (
          <>
            <h2 className="text-2xl font-bold mb-4">Alter at home 
            </h2>

            {/* Date & Time */}
            <div>
              <label htmlFor="dateTime" className="block text-sm font-medium text-gray-700">Date & Time</label>
              <input
                type="datetime-local"
                id="dateTime"
                name="dateTime"
                value={formData.dateTime}
                onChange={handleChange}
                className="mt-1 block md:w-[600px] lg:w-[600px] rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>

            {/* Duration */}
            <div>
              <label htmlFor="duration" className="block text-sm font-medium text-gray-700">Duration (hrs)</label>
              <input
                type="number"
                id="duration"
                name="duration"
                value={formData.duration}
                onChange={handleChange}
                className="mt-1 block md:w-[600px] lg:w-[600px] rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>

            {/* No of Clothes */}
            <div>
              <label htmlFor="noOfClothes" className="block text-sm font-medium text-gray-700">No of Clothes</label>
              <input
                type="number"
                id="noOfClothes"
                name="noOfClothes"
                value={formData.noOfClothes}
                onChange={handleChange}
                className="mt-1 block md:w-[600px] lg:w-[600px] rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>

            {/* Add Products */}
            <div>
              <h3 className="text-lg font-bold">Add Products</h3>
              {formData.products.map((product, index) => (
                <div key={index} className="space-y-2">
                  <div>
                    <label htmlFor={`category-${index}`} className="block text-sm font-medium text-gray-700">Category</label>
                    <select
                      id={`category-${index}`}
                      name={`category-${index}`}
                      value={product.category}
                      onChange={(e) => handleProductChange(index, 'category', e.target.value)}
                      className="mt-1 block md:w-[600px] lg:w-[600px] rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    >
                      <option>Men</option>
                      <option>Women</option>
                      <option>Kids</option>
                      <option>Home</option>
                    </select>
                  </div>
                  <div>
                    <label htmlFor={`subCategory-${index}`} className="block text-sm font-medium text-gray-700">Sub Category</label>
                    <input
                      type="text"
                      id={`subCategory-${index}`}
                      name={`subCategory-${index}`}
                      value={product.subCategory}
                      onChange={(e) => handleProductChange(index, 'subCategory', e.target.value)}
                      className="mt-1 block md:w-[600px] lg:w-[600px] rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                  <div>
                    <label htmlFor={`subSubCategory-${index}`} className="block text-sm font-medium text-gray-700">Sub Sub Category</label>
                    <input
                      type="text"
                      id={`subSubCategory-${index}`}
                      name={`subSubCategory-${index}`}
                      value={product.subSubCategory}
                      onChange={(e) => handleProductChange(index, 'subSubCategory', e.target.value)}
                      className="mt-1 block md:w-[600px] lg:w-[600px] rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                  <div>
                    <label htmlFor={`alteration-${index}`} className="block text-sm font-medium text-gray-700">Alteration</label>
                    <input
                      type="text"
                      id={`alteration-${index}`}
                      name={`alteration-${index}`}
                      value={product.alteration}
                      onChange={(e) => handleProductChange(index, 'alteration', e.target.value)}
                      className="mt-1 block md:w-[600px] lg:w-[600px] rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                  {index > 0 && (
                    <button type="button" onClick={() => handleRemoveProduct(index)} className="text-red-500 text-sm">
                      Remove Product
                    </button>
                  )}
                </div>
              ))}
              {formData.products.length < 5 && (
                <button
                  type="button"
                  onClick={handleAddProduct}
                  className="mt-2 py-1 px-4 bg-black text-white rounded-md shadow-sm"
                >
                  Add Another Product
                </button>
              )}
            </div>

            {/* Inspiration & Budget Range */}
            {/* The rest of the form remains unchanged */}
          </>
        )}

        <div>
          <button
            type="submit"
            className="md:w-[600px] lg:w-[600px] py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Submit and Proceed
          </button>
        </div>
      </form>
    </div>
  );
};

export default AnotherBannerForm3;
