import React, { useContext, useEffect, useState } from "react";
import { url } from '../api/Url';
import axios from 'axios';
import UserContext from '../../context/UserContext';
import { Link } from "react-router-dom";

// Dummy data
//const categories = ['Electronics', 'Furniture', 'Clothing'];
//const brands = ['Brand A', 'Brand B', 'Brand C'];
/* const products = [
  { id: 1, name: 'Laptop', category: 'Electronics', brand: 'Brand A', price: '$999' },
  { id: 2, name: 'Sofa', category: 'Furniture', brand: 'Brand B', price: '$499' },
  { id: 3, name: 'Shirt', category: 'Clothing', brand: 'Brand C', price: '$29' },
  // Add more products as needed
]; */

const Shopnowcomponent = () => {
  const sellerid = localStorage.getItem('seller_id');
  const sellerslug = localStorage.getItem('seller_slug');
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [brands, setBrands] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedBrand, setSelectedBrand] = useState('');

  const {
    sellerListdata,
    setSellerListdata,
    loader,
    error,
    setLoader,
    setError,
  } = useContext(UserContext);


  const filteredProducts = products.filter(product => {
    return (
      (selectedCategory ? product.category === selectedCategory : true) &&
      (selectedBrand ? product.brand === selectedBrand : true)
    );
  });

  const getCat = async () => {
    const json = JSON.stringify({ seller_id: sellerid });
    axios
      .post(url + "/app/v1/api/seller_category_list", json)
      .then((res) => {
       // console.log('..categories...' + res.data.message);
        setCategories(res.data.message.slice(0,5));
      })
      .catch(() => {
        //setCategories([]);
      });
  };
  const getBrd = async () => {
    const json = JSON.stringify({ seller_id: sellerid });
    axios.post(url + '/app/v1/api/get_brand_data_product_web', json)
      .then((res) => {
       // console.log('data brand' + JSON.stringify(res.data.message));
       setBrands(res.data.message.slice(0,8))
       // setBranddata([])
      })
      .catch(() => {
        setBrands([])
      });
  }
  const getPrd = async () => {
    const json = JSON.stringify({ seller_id: sellerid, limit : 10 });
    axios.post(url + '/app/v1/api/get_products', json)
      .then((res) => {
        console.log('get prd' + JSON.stringify(res.data.data));
       setProducts(res.data.data.slice(0,12))
       // setBranddata([])
      })
      .catch(() => {
        setProducts([])
      });
  }
  useEffect(() => {
    const fetchAllData = async () => {
      setLoader(true);
      await Promise.all([
        getCat(),
        getBrd(),
        getPrd(),
      ]);
      setLoader(false);
    };
    fetchAllData();
  }, [setLoader, setError]);
  return (
    <div className="flex h-screen bg-gray-100">
      {/* Left Sidebar */}
      <div className="w-1/4 bg-white p-4 shadow-md">
        <div className="mb-4 text-left">
          <h2 className="text-xl font-bold mb-2">Categories</h2>
          <ul>
            {/* {categories.map(category => (
              <li
                key={category}
                className={`cursor-pointer p-2 rounded hover:bg-gray-200 ${
                  selectedCategory === category ? 'bg-gray-300' : ''
                }`}
                onClick={() => setSelectedCategory(category)}
              >
                {category}
              </li>
            ))} */}
            {
              categories.map(category => (
                <li
                  key={category}
                  className={`cursor-pointer p-2 rounded hover:bg-gray-200 ${selectedCategory === category ? 'bg-gray-300' : ''
                    }`}
                  onClick={() => setSelectedCategory(category)}
                >

                  {
                    category.seller_category_name ? category.seller_category_name : category.zulu_category_name
                  }
                </li>
              ))
            }
          </ul>
        </div>
        <div className="text-left">
          <h2 className="text-xl mb-2">Brands</h2>
          <ul>
          {/*   {brands.map(brand => (
              <li
                key={brand}
                className={`cursor-pointer p-2 rounded hover:bg-gray-200 ${selectedBrand === brand ? 'bg-gray-300' : ''
                  }`}
                onClick={() => setSelectedBrand(brand)}
              >
                {brand}
              </li>
            ))} */}
            {brands.map(brand => (
              <li
                key={brand}
                className={`cursor-pointer p-2 rounded hover:bg-gray-200 ${selectedBrand === brand ? 'bg-gray-300' : ''
                  }`}
                onClick={() => setSelectedBrand(brand)}
              >
                {brand.brand_name}
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Right Side - Product List */}
      <div className="w-3/4 p-4 h-auto">
        <h1 className="text-3xl  mb-4">Products</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 " style={{ overflow: 'auto' }}>
         {/*  {filteredProducts.length > 0 ? (
            filteredProducts.map(product => (
              <div key={product.id} className="bg-white p-4 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold">{product.name}</h3>
                <p className="text-gray-700">Category: {product.category}</p>
                <p className="text-gray-700">Brand: {product.brand}</p>
                <p className="text-lg font-bold">{product.price}</p>
              </div>
            ))
          ) : (
            <p>No products found</p>
          )} */}
           {filteredProducts.length > 0 ? (
            filteredProducts.map(product => (
              <div key={product.id} className="bg-white p-4 rounded-lg shadow-md">
               <Link to={`/o/${sellerslug}/${product.id}/${product.slug}/product`}>
                <img src={`${product.image}`} alt=""  />
                </Link>
                <h3 className="text-xl font-semibold">{product.name}</h3>
             {/*    <p className="text-gray-700">Category: {product.category}</p>
                <p className="text-gray-700">Brand: {product.brand}</p>
                <p className="text-lg font-bold">{product.price}</p> */}
              </div>
            ))
          ) : (
            <p>No products found</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Shopnowcomponent;
