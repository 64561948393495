import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { json, useParams } from "react-router-dom";
import Card2 from "../../components/cards/Card2";
import Header from "../../components/header/Header";
import { url } from "../../components/api/Url";
import UserContext from "../../context/UserContext";
import Footer from "../../components/footer/Footer";
import Shopnowcomponent from "../../components/template_one/Shopnowcomponent";

const Shopnow = () => {
  const { id } = useParams();
  const [data, setData] = useState([])
  const [categoriesdata, setCategoriesdata] = useState([]);
  const [sellerslug, setSellerslug] = useState(id);
  const [sellerid, setSellerid] = useState('');
  const [isloading, setIsloading] = useState(false);
  console.log("data",data)
  const {
    sellerListdata,
    setSellerListdata,
    loader,
    error,
    setLoader,
    setError,
  } = useContext(UserContext);


  return (
    <>
      <Shopnowcomponent />
    </>
  );
};

export default Shopnow;
