import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import UserContextProvider from "./context/UserContextProvider";
import SuperTopBar from "./components/super_top_bar/SuperTopBar";
import Headerconsumer from "./components/header/Headerconsumer";
import HeaderNew from "./components/Zulu_club/header/HeaderNew";
import Marquee from "./components/cards/Marquee";

function Layoutconsumer() {
  return (
    <>
      <UserContextProvider>
        <SuperTopBar />
        <HeaderNew />
        <Marquee />
        <div className="container text-center w-[95%] mx-auto overflow-hidden mt-36">
          {/* <Headerconsumer /> */}
       
          <Outlet />
         
        </div>
        <Footer />
      </UserContextProvider>
    </>
  );
}

export default Layoutconsumer;
