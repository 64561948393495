import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginPending, loginSuccess, loginFailure } from '../../components/template_one/redux/slice/authSlice';
import { url } from '../../components/api/Url';
import { Link, useNavigate } from 'react-router-dom';
function ReduxPage() {

  const [isloading, setIsloading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { status, error  } = useSelector((state) => state.auth);


  const [formData, setFormData] = useState({
    mobile: '',
    password: ''
  });
  const [file, setFile] = useState(null);

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  // Handle file input change
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsloading(true)
    // Create a FormData object
    const datafrom = new FormData();
    datafrom.append('mobile', formData.mobile);
    datafrom.append('password', formData.password);
    console.log(datafrom);
    // Append the file
  /*   if (file) {
      data.append('file', file);
    } */

    try {
      const response = await fetch('http://localhost/Zulu-Backend/seller/app/v1/api/login', {
        method: 'POST',
        body: datafrom
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
       if(data.message == "Incorrect Login" && data.error == true)
      {
      //  alert('Invalid');
        dispatch(loginFailure(data.message));
        setIsloading(false)
      }
      else if( data.message == "Logged in successfully" && data.error == false)
      {
        dispatch(loginSuccess(data));
        setIsloading(false)
        navigate(`/reduxdashboard`);
      }
      else
      {
      //  alert('Some thing went wrong');
       // dispatch(loginFailure(err.message));
       dispatch(loginFailure(data.message));
       setIsloading(false)
      } 
      console.log('Success:', data);
    } catch (error) {
        setIsloading(false)
        dispatch(loginFailure(error.message));
    }
    setIsloading(false)
  };


  return (
    <form onSubmit={handleSubmit}>
      <label>
        Mobile:
        <input
          type="text"
          name="mobile"
          value={formData.mobile}
          onChange={handleChange}
        />
      </label>
      <br />
      <label>
        Passowrd:
        <input
          type="text"
          name="password"
          value={formData.password}
          onChange={handleChange}
        />
      </label>
      <br />
     {/*  <label>
        File:
        <input
          type="file"
          name="file"
          onChange={handleFileChange}
        />
      </label> */}
      <br />
      <button type="submit">Submit </button>
      {status === 'failed' && <p>{error}</p>
      
      }
   
    </form>
   
  );
}

export default ReduxPage;
