import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { json, useParams, Link } from "react-router-dom";
import Card2 from "../../components/cards/Card2";
import Header from "../../components/header/Header";
import { url } from "../../components/api/Url";
import UserContext from "../../context/UserContext";
import Footer from "../../components/footer/Footer";
import SellerHighlistCard from "./SellerHighlistCard";


const SellerFeaall = (data) => {
  const sellerid = localStorage.getItem('seller_id');
  const sellerslug = localStorage.getItem('seller_slug');
  const [featureData, setFeatureData] = useState([]);

  async function getFeatures() {
    const jsonData = JSON.stringify({ seller_id: sellerid });
    try {
      let response = await axios.post(url + '/app/v1/api/get_seller_features_sections_final', jsonData);
      let res = await response.data;

     // setFeatureData(res);
      if(res.message == 'No sections are available')
      {
        setFeatureData([]);
      //  console.log('empty' + JSON.stringify(res))
      }
      else
      {
        setFeatureData(res);
       // console.log('res..features.frist..' + JSON.stringify(res))
      }

    } catch (error) {
      console.error("Error submitting form:", error);
    }
  }


  useEffect(() => {
    window.scrollTo(0, 0);
    getFeatures();
  }, []);


  return (
    <>
         <div className="mt-10">
      <div className="mt-5">

        <div className="flex gap-5 flex-wrap mt-10 mb-5 justify-center items-center">
          {featureData?.map((item) => {
            return (
              <div key={item.id} className="banner">
                <SellerHighlistCard 
                  title={item.title}
                  image={`/uploads/seller/${item.banner}`}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>

    </>
  );
};

export default SellerFeaall;
