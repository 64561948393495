import React from "react";
import { Link } from "react-router-dom";
import { url } from "../api/Url";

const Threedmodel = ({ video_name, title, price, discount }) => {
  return (
    <div className="">
      {/* <div className="rounded-xl shadow-md overflow-hidden bg-slate-100 group h-[350px] w-1/5"> */}
  
      <p>{video_name}</p>
   
    </div>
  );
};

export default Threedmodel;
