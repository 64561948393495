import React, { useEffect, useState } from 'react'
import ProfileMenu from '../../components/profile/ProfileMenu'
import ViewProfile from '../../components/profile/ViewProfile'
import axios from 'axios'
import { url } from '../../components/api/Url'
import { Link } from 'react-router-dom'
import Spinner from '../../components/Spinner/Spinner'
const Profilepage = () => {
  const [profiledata, setProfiledata] = useState([]);
  const [username, setUsername] = useState('');
  const [image, setImage] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [isloading, setIsloading] = useState(true);

  const getProfile = async () => {
    setIsloading(true)
    const json = JSON.stringify({ mobileno: localStorage.getItem('mobileno') });
    axios
      .post(url + "/app/v1/api/user_profile", json, {
        headers: {
          //'Content-Type': 'application/json',
        },
      })
      .then((res) => {

        console.log('..profile...' + res.data.data[0]);
        console.log('..profile...name' + res.data.data[0].username);
        setUsername(res.data.data[0].username)
        setEmail(res.data.data[0].email)
        setUsername(res.data.data[0].username)
        setMobile(res.data.data[0].mobile)
        setIsloading(false)
        //  setProfiledata(res.data.message.slice(0, 8));
        // setProfiledata(res.data.data[0] || []);

        /*       setProfiledata({
                image: 'https://via.placeholder.com/150',
                name: res.data.data[0].username,
                email: res.data.data[0].username,
                address: res.data.data[0].username,
                phone: res.data.data[0].username,
                }); */
      })
      .catch(() => {
        setProfiledata([]);
      });
  }
  const user = {
    image: 'https://via.placeholder.com/150',
    name: 'John Doe',
    email: 'john.doe@example.com',
    address: '123 Main St, Anytown, USA',
    phone: '(123) 456-7890',
  };

  const handleFormSubmit = (updatedUser) => {
    console.log('Updated User Data:', updatedUser);
    // Here you can handle the form submission, e.g., send it to an API
  };
  useEffect(() => {
    getProfile();
  }, [])
  return (
    <>
      {
        isloading ? <Spinner />
          :
          <>
            <ProfileMenu />
            <ViewProfile username={username}
              email={email} userimage={image} mobile={mobile}
              onSubmit={handleFormSubmit} />
          </>
      }
    </>
  )
}
export default Profilepage