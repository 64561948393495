import React from "react";
import "../../../pages/zulu_club/Home.css";
import zululogo from '../../../assets/images/New_ImgesFor/mobilehome.png'
const Cont1 = ({ data }) => {
  return (
    <div>
      <div className="text-content text-white">
        {/* <h1 className=" md:text-5xl xl:text-7xl absolute top-[18%] translate-x-[20px] sm:translate-x-[100px] text-[40px] leading-[40px] font-bold headline_h1"
          style={{ textShadow: '2px 2px 2px black' }} >   
          The premium <br />
          <span className="text-yellow-200">fashion</span> Club. */}
        {/* Celebrate Your Uniqueness */}

        {/* </h1> */}
        <h3 className="text-md hidden leading-9 sm:leading-10 sm:text-2xl font-medium">{data[0].tags1}</h3>
        <h3 className="text-md hidden leading-9 sm:leading-10 sm:text-2xl font-medium">{data[0].tags2}</h3>
        <h3 className="text-md hidden leading-9 sm:leading-10 sm:text-2xl font-medium">{data[0].tags3}</h3>
        <h3 className="text-md hidden leading-9 sm:leading-10 sm:text-2xl font-medium">{data[0].tags4}</h3>
      </div>
      {/* <div className={`${data[0].imgbg} cont1 h-screen container mx-auto mt-0 bg-cover bg-center rounded-[20px]`}></div>
      <img src={zululogo} className="rounded-[35px] " alt="" /> */}

      <div className="hidden sm:block" style={{ padding: '20px' }}>
        <div className={`${data[0].imgbg} cont1 sm:h-[50vh] md:h-[64vh] lg:h-[100vh] w-full container mx-auto mt-0 bg-contain bg-center rounded-[20px]`}></div>
      </div>

      <div className="flex justify-center mt-5 sm:hidden" >
        <img src={zululogo} className="" alt="Zulu Logo" />
      </div>
      <div className="flex flex-col mt-14 w-full  gap-7">
        <div className="flex flex-col gap-7 items-center">
          <h1 className="text-5xl font-bold">Launching soon in Gurgaon </h1>
          <span className="text-[25px] font-normal">GOLF COURSE EXTN ROAD</span>
        </div>
        <div className="Boxes mt-5">
          <div className="boxes sm:pl-8 md:pl-0 flex items-center flex-col  mx-5 flex-wrap gap-7 justify-center">
            <div className="box1 flex items-center gap-7 flex-wrap">
              <h1 className="box w-[150px] h-[53px] leading-[50px] border border-white text-nowrap">M3M Merlin</h1>
              <h1 className="box w-[150px] h-[53px] leading-[50px] border border-white text-nowrap">Pioneer Park</h1>
              <h1 className="box w-[150px] h-[53px] leading-[50px] border border-white text-nowrap">Ireo Skyon</h1>
              <h1 className="box w-[150px] h-[53px] leading-[50px] border border-white text-nowrap">Ireo Uptown</h1>
              <h1 className="box w-[150px] h-[53px] leading-[50px] border border-white text-nowrap">Emerald Hills</h1>

            </div>
            {/*  */}
            <div className="box2 flex items-center gap-7 flex-wrap">

              <h1 className="box w-[150px] h-[53px] leading-[50px] border border-white text-nowrap">Ireo Corridor</h1>
              <h1 className="box w-[150px] h-[53px] leading-[50px] border border-white text-nowrap">Palm Drive</h1>
              <h1 className="box w-[150px] h-[53px] leading-[50px] border border-white text-nowrap">Bestech Parkview</h1>
              <h1 className="box w-[150px] h-[53px] leading-[50px] border border-white text-nowrap">Pioneer Araya</h1>
              <h1 className="box w-[150px] h-[53px] leading-[50px] border border-white text-nowrap">BPTP Park Prime </h1>
              <h1 className="box w-[150px] h-[53px] leading-[50px] border border-white text-nowrap">Dhoot Apartments</h1>

            </div>
            <div className="">
              <div className=" mt-7 flex flex-col gap-3">
                <h1 className="text-[20px] font-normal uppercase">Click on YOUR SOCIETY TO JOIN THE WAIT LIST</h1>
                <h1 className="text-[24px] font-bold">Memberships starting at 11 Rs per month</h1>
                <div className="mt-7  mb-14">
                  <button className="btn1 text-3xl w-[250px] uppercase">Explore</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Cont1;