import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const ProductContext = createContext();

export const ProductProvider = ({ children }) => {
  const [products, setProducts] = useState(() => {
    const storedProducts = localStorage.getItem('products');
    return storedProducts ? JSON.parse(storedProducts) : [];
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(() => {
    const storedProduct = localStorage.getItem('selectedProduct');
    return storedProduct ? JSON.parse(storedProduct) : null;
  });
  const [filterOptions, setFilterOptions] = useState(() => {
    const storedOptions = localStorage.getItem('filterOptions');
    return storedOptions ? JSON.parse(storedOptions) : {
      brand: [],
      category: [],
      priceRanges: []
    };
  });

  // Define the fetchProductsAndFilters function
  const fetchProductsAndFilters = async () => {
    if (products.length > 0) {
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post('https://zulushop.in/app/v1/api/get_products');
      const productsData = response.data.data || [];
      setProducts(productsData);
      localStorage.setItem('products', JSON.stringify(productsData));
      
      // Extract unique brands and categories
      const brands = [...new Set(productsData.map(product => product.brand))];
      const categories = [...new Set(productsData.map(product => product.category_name))];

      // Generate price ranges
      const prices = productsData.map(product => product.min_max_price.special_price);
      const minPrice = Math.min(...prices);
      const maxPrice = Math.max(...prices);
      const priceStep = (maxPrice - minPrice) / 4; 
      const priceRanges = [
        { min: minPrice, max: minPrice + priceStep },
        { min: minPrice + priceStep, max: minPrice + 2 * priceStep },
        { min: minPrice + 2 * priceStep, max: minPrice + 3 * priceStep },
        { min: minPrice + 3 * priceStep, max: maxPrice }
      ];

      const newFilterOptions = {
        brand: brands,
        category: categories,
        priceRanges: priceRanges
      };

      setFilterOptions(newFilterOptions);
      localStorage.setItem('filterOptions', JSON.stringify(newFilterOptions));

      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProductsAndFilters();
  }, []);

  useEffect(() => {
    localStorage.setItem('selectedProduct', JSON.stringify(selectedProduct));
  }, [selectedProduct]);

  const selectProduct = (product) => {
    setSelectedProduct(product);
  };

  return (
    <ProductContext.Provider value={{ 
      products, 
      loading, 
      error, 
      selectedProduct, 
      selectProduct,
      filterOptions 
    }}>
      {children}
    </ProductContext.Provider>
  );
};

export const useProduct = () => useContext(ProductContext);
