import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import axios from "axios";
import { url } from "../../components/api/Url";
import SellerFeatureCard from "./SellerFeatureCard";

const Seller_features = ({data}) => {
  const [featuredata, setFeaturedata] = useState([]);
  // const [product, setProduct] = useState([]);
  const sellerid = localStorage.getItem('seller_id');
  const sellerslug = localStorage.getItem('seller_slug');
  
  async function getFeatures() {
    const jsonData = JSON.stringify({ seller_id: sellerid });
    try {
      let response = await axios.post( url + '/app/v1/api/get_seller_features_sections_final', jsonData);
      let res = await response.data;
      //console.log('res..show error' + res.message)
      if(res.message == 'No sections are available')
      {
        setFeaturedata([]);
       // console.log('empty' + JSON.stringify(res))
      }
      else
      {
        setFeaturedata(res);
      //  console.log('res..features.frist..' + JSON.stringify(res))
      }
    
     
     // setFeatureData([])
     // alert(res.length);
     /* if(res.error == 'true')
     {
      setFeatureData([])
     }
     else
     {
      setFeatureData(res);
     } */
      /* if(res.length == "undefined")
      {
        setFeatureData(res);
      }
      else
      {
        setFeatureData([])
      } */
     
    } catch (error) {
      setFeaturedata([])
      console.error("Error submitting form:", error);
    }
  }
  // console.log(featureData);

  let productDetails = featuredata[0]?.product_details.productinfo;
  // let productDetails2 = featureData[0]?.product_details.productinfo;

  // console.log(productDetails);

  useEffect(() => {
    getFeatures();
  }, []);

  // console.log(featureData[0].product_details);

  return (
    <div className="mt-10">
      <div className="mt-5">
        {
        featuredata && featuredata?.map((item) => {
          return (
            <div key={item.id}>
              <div className="flex justify-between mt-10 mb-5 items-center">
                <div className="flex flex-col gap-2">
                  <h1 className="text-left font-bold text-[22px]" style={{color:`${data?.component?.image_gallery?.color}`}}>{item.title}</h1>
                  <p className="text-left">{item.short_description}</p>
                </div>
                <Link to={`/o/${sellerslug}/${item.id}/features-section`} className="text-blue-700">
                  view all
                </Link>
              </div>
              <div className="flex justify-center md:justify-between mt-10 mb-5">
                {productDetails && productDetails?.map((product) => (
                  <div key={product.id}  className="grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 ">
                    <SellerFeatureCard className="pt-4" style={{ padding: 10 }}
                      image={product.image}
                      title={product.name}
                      sellerslug={sellerslug}
                      productid={product.id}
                      slugprd={product.slug}
                      price={product.variants[0].price}
                      discount={product.variants[0].special_price}
                    />
                  </div>
                ))}
              </div> 
            </div>
          );
        })
      }
      </div>
    </div>
  );
};

export default Seller_features;
