import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
const url = "http://localhost/Zulu-Backend";
const initialState = {
  posts: [],
  total: 0,
  totalPages: 0,
  currentPage: 1,
  status: 'idle',
  error: null,
};

export const fetchPosts = createAsyncThunk(
  'posts/fetchPosts',
  async (page = 1) => {
    const response = await axios.get(url + `/Sellersfrontend/postlist/${page}`);
    return response.data;
  }
);

export const createPost = createAsyncThunk(
  'posts/createPost',
  async (post) => {
    await axios.post(url + '/sellersfrontend/poststore', post);
    return post;
  }
);

export const updatePost = createAsyncThunk(
  'posts/updatePost',
  async ({ id, post }) => {
    await axios.put(url + '/sellersfrontend/postupdate/${id}', post);
    return { id, post };
  }
);

export const deletePost = createAsyncThunk(
  'posts/deletePost',
  async (id) => {
    await axios.delete(url + `/sellersfrontend/postdelete/${id}`);
    return id;
  }
);

const postsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPosts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPosts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.posts = action.payload.posts;
        state.total = action.payload.total;
        state.totalPages = action.payload.total_pages;
      })
      .addCase(fetchPosts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(createPost.fulfilled, (state, action) => {
        state.posts.push(action.payload);
      })
      .addCase(updatePost.fulfilled, (state, action) => {
        const index = state.posts.findIndex(post => post.id === action.payload.id);
        if (index >= 0) {
          state.posts[index] = action.payload.post;
        }
      })
      .addCase(deletePost.fulfilled, (state, action) => {
        state.posts = state.posts.filter(post => post.id !== action.payload);
      });
  },
});

export const { setCurrentPage } = postsSlice.actions;
export default postsSlice.reducer;
