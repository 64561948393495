import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import axios from "axios";
import { url } from "../../components/api/Url";
import SellerFeatureCard from "./SellerFeatureCard";
import SellerHighlistCard from "./SellerHighlistCard";

const Seller_Features_All = ({data, sellerid}) => {

    const sellerslug = localStorage.getItem('seller_slug');
    const [featureData, setFeatureData] = useState([]);
    // const [product, setProduct] = useState([]);
  
  
    async function getFeatures() {
      const jsonData = JSON.stringify({ seller_id: sellerid });
      try {
        let response = await axios.post(url + '/app/v1/api/get_seller_features_sections_final', jsonData);
        let res = await response.data;
  
       // setFeatureData(res);
        if(res.message == 'No sections are available')
        {
          setFeatureData([]);
        //  console.log('empty' + JSON.stringify(res))
        }
        else
        {
          setFeatureData(res);
         // console.log('res..features.frist..' + JSON.stringify(res))
        }
  
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  
  
    useEffect(() => {
      getFeatures();
    }, []);
  

  // console.log(featureData[0].product_details);

  return (
    <div className="mt-10">
      <div className="mt-5">
        <div className="flex justify-between">
          <div>
            <h1 className="text-left font-bold text-[22px]" style={{color:`${data?.component?.image_gallery?.color}`}}>{data?.component?.features_section?.title}</h1>
            {/* <p>{item.short_description}</p> */}
          </div>
          <Link to={`/o/${sellerslug}/featuresall`} className="text-blue-700">
            view all
          </Link>
        </div>
        <div className="flex gap-5 flex-wrap mt-10 mb-5 justify-center items-center">
          {featureData?.map((item) => {
            return (
              <div key={item.id} className="banner">
                <SellerHighlistCard 
                  title={item.title}
                  id={item.id}
                  image={`/uploads/seller/${item.banner}`}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>

  );
};

export default Seller_Features_All;

