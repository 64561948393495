import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Banner4 from '../../components/cards/Banner4';
import UserContext from "../../context/UserContext";
import CustomBannerForm from './Forms/CustomBannerForm';
import BannerForm5 from './Forms/AnotherBannerForm';
import BannerForm6 from './Forms/CustomBannerForm';
import BannerForm7 from './Forms/AnotherBannerForm2';
import BannerForm8 from './Forms/AnotherBannerForm3';
import BannerForm10 from './Forms/AnotherBannerForm4';

const BannerDetails = () => {
  const { id } = useParams(); // Get the banner ID from the URL
  const navigate = useNavigate();
  const sliderUrl = "https://zulushop.in/app/v1/api/get_slider_backup2";
  const [sliderImagesdata, setSliderImagesData] = useState([]);
  const [sliderImagesMain, setSliderImagesMain] = useState([]);
  const { setError, setLoader } = useContext(UserContext);
  const baseUrl = "https://zulushop.in/";
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchSliderData();
  }, []);

  const fetchSliderData = async () => {
    try {
      setLoader(true);
      const response = await axios.post(sliderUrl);
      const res = response.data;
      setSliderImagesData(res);
      if (Array.isArray(res)) {
        const bannerImages = res.map((item) => `${baseUrl}${item.image}`);
        setSliderImagesMain(bannerImages);
      }
      setLoader(false);
    } catch (error) {
      console.error("Error fetching slider images:", error);
      setError(true);
      setLoader(false);
    }
  };

  const bannerDetails = sliderImagesdata.find(slider => slider.id === id);

  const handleFormSubmit = (formData) => {
    console.log('Form submitted:', formData);
    setIsModalOpen(false);
    // Handle form submission logic here
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const formMapping = {
    '5': BannerForm5,   // Banner with ID 5 uses AnotherBannerForm
    '6': BannerForm6,   // Banner with ID 6 uses AnotherBannerForm
    '7': BannerForm7,   // Banner with ID 7 uses AnotherBannerForm
    '8': BannerForm8,    // Banner with ID 8 uses CustomBannerForm
    '10': BannerForm10,   // Banner with ID 10 uses CustomBannerForm
  };
  
  const renderFormByBanner = (banner) => {
    if (!banner) return null;
  
    // Get the form component from the mapping based on banner ID
    const FormComponent = formMapping[banner.id] || CustomBannerForm;
  
    // Render the selected form with the bannerId and onSubmit handler
    return <FormComponent bannerId={banner.id} onSubmit={handleFormSubmit} />;
  };
  
  if (!bannerDetails) {
    return <p className='text-lg font-semibold'>No banner found with the given ID</p>;
  }

  return (
    <div>
      <div className='mb-[20px]'>
        <div className='flex justify-start'>
          <div
            onClick={() => navigate(-1)}
            className='text-gray-500 font-bold px-6 rounded cursor-pointer items-center flex transition duration-300'
          >
            <img className='w-4 h-4 mr-2' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAABZklEQVR4nO3YzSpFURQH8JXcAQZmHsADeABPQGQgXuHk7H2LtY4yof+6MVAyk7pjypx4BYLI2ATla0BmJkp0yOe5Nx8pd93Wr9Z8/1tn7732IXLOOeecc+4vRcFQYD0PguuQYYQsKmeV/ii4i6IPT8V6Rdakgt4oevsa4jnIJVmSTqInMm7ehwis92WpjJIV6QS6o+DiQyeea5ysGMvQFVgPP4cIjGmyIpma74yiBzVCLJEVSYJ2yLpTCCG6AqCFLEiSaikwtop7AusAWskCAC1BdK0QgnU37xJZERnLNU6nRq/j/KJ+DREYcw2wqF8Wzt66ITrbFEGsflqBcRQYffSdzR4Ee1m20EGWJEm1FEU3i8l1w8zx+4J5sS0ItgutZKyauRDfjyiBsW96RPlyaBSdIWvSemM8Y4KsSes8rNIMw9QcT12cUDP8fAisp2RVFAzmg1oeIs0qA/+9Huecc8455+gnHgH0wdcrlZl01QAAAABJRU5ErkJggg==" />
            Back
          </div>
        </div>
        {sliderImagesMain.length > 0 && (
          <Banner4 className="mx-auto" images={sliderImagesMain} data={sliderImagesdata} />
        )}
      </div>
      <div className='flex flex-col items-center justify-center min-h-screen p-2 mb-20'>
        <div className='w-full max-w-6xl mx-auto mt-[-200px] bg-white rounded-lg h-[400px] shadow-lg overflow-hidden'>
          <div className='flex flex-col md:flex-row'>
            <div className='md:w-1/4'>
              <img
                src={`${baseUrl}${bannerDetails.image}`}
                alt="Banner"
                className='object-cover md:w-[300px]  min-h-[400px]'
              />
            </div>
            <div className='md:w-3/4 p-8'>
              <p className='text-gray-600 mb-4 text-left font-bold text-4xl'>{bannerDetails.text1 || 'Default text'}</p>
              <p className='text-gray-600 mb-4 text-left font-bold'>{bannerDetails.text2 || 'Default text'}</p>
              <p className='text-gray-600 mb-4 text-left'>{bannerDetails.text3 || 'Default text'}</p>
              <p className='text-gray-600 mb-4 text-left'>{bannerDetails.text4 || 'Default text'}</p>
              {/* <button
                onClick={() => setIsModalOpen(true)}
                className='py-2 px-4 bg-black text-white rounded-lg shadow-md'
              >
     Get Started
              </button> */}
            </div>
          </div>
        </div>
      </div>
      {/* {isModalOpen && ( */}
        <div className=' flex items-center justify-center mt-[-300px]'>
          <div className='bg-white p-6 rounded-lg shadow-lg w-full text-left'>
            {/* <button
              onClick={handleCloseModal}
              className='absolute top-2 right-[31vw] text-gray-600 hover:text-gray-900 font-bold text-3xl'
              aria-label="Close"
            >
              &times;
            </button> */}
            {renderFormByBanner(bannerDetails)}
          </div>
        </div>
      {/* )} */}
    </div>
  );
};

export default BannerDetails;
