import React from "react";
import { FaBolt, FaFireAlt, FaHome, FaShoppingBag, FaTruck, FaGift, FaThumbsUp } from "react-icons/fa";

const Marquee = () => {
  const items = [
    { icon: FaThumbsUp, text: "Customer Satisfaction" },
    { icon: FaGift, text: "Exclusive Gifts" },
    { icon: FaTruck, text: "Fast Delivery" },
    { icon: FaShoppingBag, text: "Hassle-Free Returns" },
    { icon: FaHome, text: "Try & Buy" },
    { icon: FaFireAlt, text: "Latest Trends" },
    { icon: FaBolt, text: "Weekly Drops" },
  ];

  return (
    <div className=" top-[80px] relative w-full h-6 overflow-hidden mb-[-50px] bg-blue-300">
      <div className="flex animate-marquee whitespace-nowrap">
        {[...items, ...items].map((item, index) => (
          <div key={index} className="flex items-center gap-2 mx-4 my-auto">
            <item.icon className="text-white" />
            <span className="text-white text-sm font-bold">{item.text}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Marquee;