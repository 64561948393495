import React, { useEffect, useState } from "react";

import { url } from "../../components/api/Url";
import axios from "axios";
import { Link } from "react-router-dom";
import Card_seller_cat from "../../components/cards/Card_seller_cat";

const Seller_Categories_menu = ({data}) => {
  const sellerid = localStorage.getItem('seller_id');
  const sellerslug = localStorage.getItem('seller_slug');
  console.log('child categories menu' + data + '...seller...' + sellerid)
  const [categoriesdata, setCategoriesdata] = useState([]);
   const getCat = async () => {
    const json = JSON.stringify({ seller_id: sellerid });
    axios
      .post(url + "/app/v1/api/seller_category_list", json)
      .then((res) => {
      //  console.log('..categories...' + res.data.message);
        setCategoriesdata(res.data.message.slice(0, 8));
      })
      .catch(() => {
        setCategoriesdata([]);
      });
  }; 
  useEffect(() => {
    getCat();
  }, []);
  return (
    <div className="mt-10">
      <div className="mt-5">
      {/*    <div className="flex justify-between mt-10 mb-5 items-center">
          <div className="">
            <h1 className="text-left font-bold text-2xl">Categories  </h1>
             {JSON.stringify(categoriesdata)}  
           </div>
          <Link to={`/o/${sellerslug}/categories-all`} className="text-blue-700">
            view all
          </Link> 
         </div>  */}
        <div className="">
          {/* {
            data?.component?.image_gallery?.gallery === 
          } */}
          <div className="flex justify-center md:justify-between mt-5 gap-5 md:gap-8 flex-wrap"> 
          {categoriesdata &&
            categoriesdata?.map((user) => (
              <div key={user.id}>
                <Card_seller_cat
                  className="p-4 "
                  zulu_category_name={user.zulu_category_name}
                  seller_category_name={user.seller_category_name}
                  zulu_category_image={user.zulu_category_image}
                  seller_category_image={user.seller_category_image}
                  zulu_category_id={user.zulu_category_id}
                />
              </div>
            ))}
            </div>
        </div>
      </div>
    </div>
  );
};

export default Seller_Categories_menu;
