import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom"; // UseParams to get the ID from the URL
import { url } from "../../components/api/Url";
import axios from "axios";

const BlogDetails = () => {
  const [blogDetails, setBlogDetails] = useState(null); // State to hold specific blog data
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams(); // Extract the blog ID from the URL
  const navigate = useNavigate();

  const fetchBlogDetails = async () => {
    const json = JSON.stringify({ seller_id: "85" });

    try {
      const res = await axios.post(url + "/app/v1/api/listofblog", json);
      console.log("API Response:", res.data); // Log the full API response to inspect

      // Find the blog with the matching id from the response
      const foundBlog = res.data.message.find((blog) => blog.id === id);

      if (foundBlog) {
        setBlogDetails(foundBlog); // Set the specific blog details
      } else {
        setError("Blog not found");
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching blog data:", error);
      setError("Failed to fetch blog details.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBlogDetails(); // Fetch the blog details when the component mounts
  }, [id]);

  const handleBlogClick = () => {
    navigate("/AllBlogs"); // Navigate to all blogs when necessary
  };

  if (loading) return <p>Loading blog details...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="">
      {blogDetails ? (
        <div>
          {/* Blog Image */}
          <img
            src={`${url}/uploads/seller/${blogDetails.Blogimage}`}
            alt={blogDetails.Title}
            className="w-full h-[350px] object-cover rounded-lg mb-6"
          />
          
          {/* Blog URL and Date */}
          <div className="flex justify-between items-center mb-4">
            <a
              href={`https://${blogDetails.Bloglink}`}
              className="text-blue-600 hover:underline text-sm"
              target="_blank"
              rel="noopener noreferrer"
            >
              {blogDetails.Bloglink}
            </a>
            <span className="text-gray-500 text-sm">
              {new Date(blogDetails.created_by).toDateString()}
            </span>
          </div>

          {/* Blog Title */}
          <h1 className="text-3xl font-bold text-gray-900 mb-4">
            {blogDetails.Title}
          </h1>

          {/* Blog Tags */}
          <div className="flex space-x-2 mb-6">
            {blogDetails.Titletag?.split(',').map((tag, index) => (
              <span
                key={index}
                className="px-3 py-1 text-xs bg-gray-200 text-gray-600 rounded-full"
              >
                {tag.trim()}
              </span>
            ))}
          </div>

          {/* Blog Content */}
          <p className="text-gray-700 text-sm text-left w-[1000px] mx-auto leading-relaxed">
            {blogDetails.Content}
          </p>


          {/* Back Button */}
          <button
            onClick={handleBlogClick}
            className="mt-6 px-4 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-500 transition duration-300 ease-in-out"
          >
            Back to All Blogs
          </button>
        </div>
      ) : (
        <p className="text-gray-500 text-center">No blog details available.</p>
      )}
    </div>
  );
};

export default BlogDetails;
