import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { url } from "../../components/api/Url";
import ProductCard from "../../components/cards/ProductCard";
import FilterDropdown from "../../components/categaries_menu/FilterDropdown";
import { FaSliders } from "react-icons/fa6";
import ProductCard2 from "../../components/cards/ProductCard2";

const HighlightDetails = ({ data }) => {
  const [highlightData, setHighlightData] = useState(null);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedPriceRange, setSelectedPriceRange] = useState("");
  const [sortOption, setSortOption] = useState("");

  const highlightsUrl = "https://zulushop.in/app/v1/api/get_seller_features_sections_final";

  const getHighlightDetails = async () => {
    setLoading(true);
    setError(null);
    const jsonData = JSON.stringify({ seller_id: "85" });

    try {
      const response = await axios.post(highlightsUrl, jsonData);
      let res = response.data;

      console.log("Highlight Details API Response:", res);

      if (Array.isArray(res)) {
        const selectedHighlight = res.find(item => item.id === id);
        if (selectedHighlight) {
          setHighlightData(selectedHighlight);
          console.log("Selected Highlight Data:", selectedHighlight);

          await getProducts(selectedHighlight);
        } else {
          setError(`Highlight with ID ${id} not found`);
        }
      } else {
        setError("Unexpected data structure in API response");
      }
    } catch (error) {
      console.error("Error fetching highlight details:", error);
      setError("Failed to fetch highlight details");
    }
  };

  const getProducts = async (highlightData) => {
    try {
      if (highlightData && highlightData.product_details && highlightData.product_details.productinfo) {
        // Transform the product data to ensure it has the required fields
        const transformedProducts = highlightData.product_details.productinfo.map(product => ({
          ...product,
          discount_in_percentage: product.discount_in_percentage || 0,
          // Add any other required fields with default values if they're missing
        }));
        setProducts(transformedProducts);
      } else {
        setError("No product details found for this highlight");
      }
    } catch (error) {
      console.error("Error setting products:", error);
      setError("Failed to set products");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getHighlightDetails();
  }, [id]);

  const handleRemoveFilter = (filter) => {
    if (filter === "brand") setSelectedBrand("");
    if (filter === "category") setSelectedCategory("");
    if (filter === "priceRange") setSelectedPriceRange("");
  };

  const handleClearAllFilters = () => {
    setSelectedBrand("");
    setSelectedCategory("");
    setSelectedPriceRange("");
  };

  if (loading) {
    console.log("Loading...");
    return <p>Loading highlight details and products...</p>;
  }
  if (error) {
    console.log("Error:", error);
    return <p>Error: {error}</p>;
  }
  if (!highlightData) {
    console.log("No highlight data available");
    return <p>No highlight data available</p>;
  }

  console.log("Highlight Data:", highlightData);
  console.log("Products Data:", products);

  return (
    <div className="mt-[-20px] p-4">
      <h1 className="text-left font-bold text-[28px] mb-4" style={{ color: `${data?.component?.image_gallery?.color}` }}>
        {highlightData.title || highlightData.name}
      </h1>
      <img 
        src={`${url}/uploads/seller/${highlightData.banner}`}
        alt={highlightData.name || `Collection ${highlightData.id}`} 
        className="w-full h-64 object-contain items-start rounded-lg mb-4" 
      />

      <div className="flex flex-col md:flex-row mt-8">
       

        {/* Right Section for Products */}
        <div className="w-full md:w-3/4">
          {/* Active Filters Section */}
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xs">{products.length} items</h2>
            <div className="flex items-center space-x-4">
              {/* Filter tags */}
              <div>
                {selectedBrand && (
                  <span className="bg-gray-200 px-2 py-1 rounded-lg text-sm mr-2">
                    {selectedBrand}
                    <button className="ml-2 text-red-500" onClick={() => handleRemoveFilter("brand")}>&times;</button>
                  </span>
                )}
                {selectedCategory && (
                  <span className="bg-gray-200 px-2 py-1 rounded-lg text-sm mr-2">
                    {selectedCategory}
                    <button className="ml-2 text-red-500" onClick={() => handleRemoveFilter("category")}>&times;</button>
                  </span>
                )}
                {selectedPriceRange && (
                  <span className="bg-gray-200 px-2 py-1 rounded-lg text-sm mr-2">
                    Price: {selectedPriceRange}
                    <button className="ml-2 text-red-500" onClick={() => handleRemoveFilter("priceRange")}>&times;</button>
                  </span>
                )}
                {(selectedBrand || selectedCategory || selectedPriceRange) && (
                  <button className="ml-2 text-blue-500" onClick={handleClearAllFilters}>Clear All</button>
                )}
              </div>

              {/* Sort Options */}
              <select
                className="border rounded-md p-2"
                value={sortOption}
                onChange={(e) => setSortOption(e.target.value)}
              >
                <option value="">Sort By</option>
                <option value="price-high">Price: Low to High</option>
                <option value="price-low">Price: High to Low</option>
              </select>
            </div>
          </div>

          {/* Product Grid */}
          <div className="grid grid-cols-1 sm:grid-cols-2 mx-[-30px] md:grid-cols-3 lg:grid-cols-4 gap-6">
            {products.length > 0 ? (
              products.map((product) => (
                <Link
                  key={product.id}
                  to={`/productdetail/${product.id}`}
                  className="ml-16"
                >
                  <ProductCard2 className="ml-8" product={product} />
                </Link>
              ))
            ) : (
              <div className="col-span-full text-center py-8">
                No products found
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HighlightDetails;