import React, { useState, useEffect } from "react";
import Card3 from "../cards/Card3";
import { Link } from "react-router-dom";
import axios from "axios";
import { url } from "../api/Url";

const Categories = ({data}) => {
  const [categoriesdata, setCategoriesdata] = useState([]);
  const sellerid = localStorage.getItem('seller_id');
  const sellerslug = localStorage.getItem('seller_slug');

  const getCat = async () => {
    const json = JSON.stringify({ seller_id: "85" });
    try {
      let response = await axios.post(
        url + "/app/v1/api/seller_category_list",
        json
      );
      let data = await response.data.message;
      let setPrimaryCategory = data.filter((item) => item.is_primary === "yes");
      // console.log(setPrimaryCategory);
      setCategoriesdata(setPrimaryCategory.slice(0,4));
    } catch (error) {
      setCategoriesdata([]);
    }
  };
  useEffect(() => {
    getCat();
  }, []);
  return (
    <div className="mt-10">
      <div className="flex justify-between items-center">
        <h1 className="text-left font-bold text-[22px]" style={{color:`${data?.component?.image_gallery?.color}`}}>{data?.component?.categorycard?.title}</h1>
        <Link to={`/o/${sellerslug}/categorie-all`}  className="text-blue-700">
          view all
        </Link>
      </div>
      <div className="flex justify-center md:justify-between gap-12 mt-5">
        {categoriesdata.map((item,index) => (
          <Link to="/" key={index} className="lg:grid-cols-3 md:grid-cols-2">
            {/* <Link to={`/categories/${item.category_id}`} key={item.category_id}> */}
            <Card3  image={item.zulu_category_image} title={item.zulu_category_name} />
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Categories;
