// src/components/ProfileView.js
import React, { useState } from 'react';

const ViewProfile = ({ username, email, userimage, mobile, onSubmit }) => {
  const [image, setImage] = useState(userimage);
  const [newImage, setNewImage] = useState(null);

  const [formData, setFormData] = useState({
    name: username || '',
    email: email || '',
    mobile: mobile || '',
  });


  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setNewImage(reader.result);
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
   // onSubmit({ ...formData, image: newImage || image });
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md max-w-md mx-auto">
        <hr></hr>


      <div className="bg-white p-6 rounded-lg shadow-md max-w-md mx-auto">
      <form onSubmit={handleSubmit} className="flex flex-col items-center">
        <div className="relative mb-4">
          <img
            src={image}
            alt="Profile"
            className="w-24 h-24 rounded-full object-cover border-2 border-gray-200"
          />
          <label
            htmlFor="file-input"
            className="absolute bottom-0 right-0 p-1 bg-blue-500 text-white rounded-full cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5.121 15.363a3 3 0 01-1.065-4.5l.528-.527a6.5 6.5 0 019.19-.15l.18.183a6.5 6.5 0 01-4.5 10.05h-.001a3.002 3.002 0 01-2.881-1.643m-3.24-1.044A3.001 3.001 0 015.12 15.363z"
              />
            </svg>
            <input
              id="file-input"
              type="file"
              accept="image/*"
              className="hidden"
              onChange={handleImageChange}
            />
          </label>
        </div>
        <div className="w-full mb-4 text-left">
          <label className="block text-gray-700">User Name</label>
          <input
            type="text"
            name="name"
            value={username}
            onChange={handleInputChange}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
            required
          />
        </div>
        <div className="w-full mb-4 text-left">
          <label className="block text-gray-700">Mobile No</label>
          <input
            type="tel"
            name="phone"
            value={mobile}
            onChange={handleInputChange}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
          />
        </div>
        <div className="w-full mb-4 text-left">
          <label className="block text-gray-700">Email</label>
          <input
            type="email"
            name="email"
            value={email}
            onChange={handleInputChange}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
            required
          />
        </div>
        <div className="w-full mb-4 text-left">
          <label className="block text-gray-700">Old Password</label>
          <input
            type="text"
            name="address"
            value=""
            onChange={handleInputChange}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
          />
        </div>
        <div className="w-full mb-4 text-left">
          <label className="block text-gray-700 text-left">New Password</label>
          <input
            type="tex"
            name="phone"
            value=""
            onChange={handleInputChange}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
          />
        </div>
        <div className="w-full mb-4 text-left">
          <label className="block text-gray-700">Confirm Password</label>
          <input
            type="text"
            name="phone"
            value=""
            onChange={handleInputChange}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
          />
        </div>
        <button
          type="submit"
          className="w-full py-2 px-4 bg-blue-500 text-white rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          Submit
        </button>
      </form>
    </div>
    </div>
  );
};

export default ViewProfile;
