import React from "react";
import a1 from "../../../assets/images/high_quality/infinite choices.png";
const Ccont1 = () => {
  return (
    <div className="container overflow-hidden">
      <img src={a1} alt="" className="w-full" />
    </div>
  );
};

export default Ccont1;
