import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { url } from "../api/Url";  // Adjust as needed for base URL

const AllModel3D = ({ data, queryParams }) => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch videos from the API based on queryParams
  const getVideos = async () => {
    try {
      const response = await axios.post(`${url}/get_all_videos`, queryParams);
      const videoData = response.data.message;

      // Remove duplicates based on video name and filter by '3dModel' type
      const seenNames = new Set();
      const unique3DModelVideos = videoData.filter(video => {
        if (!seenNames.has(video.video_name) && video.video_type === "3dModel") {
          seenNames.add(video.video_name);
          return true;
        }
        return false;
      });

      console.log("Unique 3D Model Videos fetched:", unique3DModelVideos);
      setVideos(unique3DModelVideos);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching videos:", err);
      setError(err.response?.data?.message || "Failed to load videos");
      setLoading(false);
    }
  };

  useEffect(() => {
    getVideos();
  }, [queryParams]);

  // Show loading spinner
  if (loading) {
    return <div>Loading...</div>;
  }

  // Show error if fetching fails
  if (error) {
    return <div className="text-red-500">Error: {error}</div>;
  }

  return (
    <>
      <div className="mt-10">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-left font-bold text-[22px]" style={{ color: `${data?.component?.image_gallery?.color}` }}>
            3D Models
          </h1>
          <Link to="" className="text-blue-700">
            View All
          </Link>
        </div>

        {/* Display videos in a responsive grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {videos.length > 0 ? (
            videos.map((video) => (
              <div key={video.id} className="bg-gray-100 p-3 rounded shadow-md">
                {/* Display the video using the weblink */}
                {video.weblink ? (
              <video
              src={`${url}/get_all_videos/${video.weblink}`}  // Adjust the path as needed
              title={video.video_name}
              className="w-full h-40 mb-2"
            />
            
                ) : (
                  <div className="w-full h-40 bg-gray-300 flex items-center justify-center rounded-md mb-2">
                    <span>No Video Available</span>
                  </div>
                )}

                {/* Video name */}
                <h2 className="font-semibold text-sm">{video.video_name || `Video`}</h2>

                {/* Video description */}
                {video.video_description && (
                  <p className="text-sm text-gray-600">{video.video_description}</p>
                )}
              </div>
            ))
          ) : (
            <p>No 3D model videos available</p>
          )}
        </div>
      </div>
    </>
  );
};

export default AllModel3D;
