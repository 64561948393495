import React, { useEffect, useState } from "react";
import Card2 from "../cards/Card2";
import axios from "axios";
import { Link } from "react-router-dom";
import { useCategory } from "./CategoryContext";

const CategoriesMenu2 = () => {
  const [categoriesData, setCategoriesData] = useState([]);
  const [categoriesId, setCategoriesId] = useState([]);
  const { selectedCategory } = useCategory();

  // Recursively flatten categories including nested ones
  const flattenCategories = (categories) => {
    let flatList = [];

    categories.forEach((category) => {
      flatList.push(category);

      if (category.children && Array.isArray(category.children)) {
        flatList = flatList.concat(flattenCategories(category.children));
      }
    });

    return flatList;
  };

  const getCategories = async () => {
    const categoriesUrl = "https://zulushop.in/zulu_category_list";

    try {
      const response = await axios.post(categoriesUrl);
      let res = response.data;

      console.log("Categories API response:", res);

      if (typeof res === "string") {
        try {
          res = JSON.parse(res);
        } catch (error) {
          console.error("Error parsing JSON response:", error);
          setCategoriesData([]);
          return;
        }
      }

      if (Array.isArray(res.data)) {
        const flattenedCategories = flattenCategories(res.data);
        console.log("Flattened categories:", flattenedCategories);

        let categories;
        if (selectedCategory) {
          categories = selectedCategory.children.map((child) => ({
            zulu_category_name: child.name,
            zulu_category_image: `${child.image}`,
            zulu_category_id: child.id,
          }));
        } else {
          categories = flattenedCategories
            .filter((category) =>
              categoriesId.includes(category.id.toString())
            )
            .map((category) => ({
              zulu_category_name: category.name,
              zulu_category_image: `${category.image}`,
              zulu_category_id: category.id,
            }));
        }

        console.log("Filtered categories:", categories);
        setCategoriesData(categories);
      } else {
        console.warn("Unexpected data structure:", res);
        setCategoriesData([]);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
      setCategoriesData([]);
    }
  };

  // Fetch categories ID from the Webcontrol API and combine them
  const getWebcontrolCategories = async () => {
    const webcontrolUrl = "https://zulushop.in/app/v1/api/list_of_webcontroll";
    try {
      const response = await axios.post(webcontrolUrl);
      const res = response.data;

      console.log("Webcontrol API response:", res);

      if (Array.isArray(res.message)) {
        // Extract and combine all category IDs from both objects
        let categoryIds = [];
        res.message.forEach((item) => {
          categoryIds = categoryIds.concat(item.categories_id.split(","));
        });

        console.log("Combined category IDs from Webcontrol API:", categoryIds);
        setCategoriesId(categoryIds);
      } else {
        console.warn("Unexpected data structure from webcontrol:", res);
      }
    } catch (error) {
      console.error("Error fetching webcontrol categories:", error);
    }
  };

  useEffect(() => {
    getWebcontrolCategories();
  }, []);

  useEffect(() => {
    if (categoriesId.length > 0 || selectedCategory) {
      console.log(
        "Categories ID state updated or category selected, calling getCategories:",
        categoriesId
      );
      getCategories();
    }
  }, [categoriesId, selectedCategory]);

  return (
    <div className="mt-6">
      <div className="mt-3">
        {/* Flexbox for horizontal scroll on mobile, grid for larger screens */}
        <div className="flex overflow-x-auto space-x-4 md:grid md:grid-cols-6 md:gap-4 w-full sm:w-[90%] mx-auto">
          {categoriesData.length > 0 ? (
            categoriesData.slice(0, 6).map((category) => (
              <Link
                key={category.zulu_category_id}
                to={`/productcategory/${category.zulu_category_id}`}
                className="no-underline flex-shrink-0 w-[150px] sm:w-auto"
              >
                <Card2
                  className="p-1"
                  zulu_category_name={category.zulu_category_name}
                  zulu_category_image={category.zulu_category_image}
                  zulu_category_id={category.zulu_category_id}
                />
              </Link>
            ))
          ) : (
            <p>No categories to display</p>
          )}
        </div>
        <div className="flex justify-between mt-5 mb-3 mx-[5%] items-center">
          <Link to="/AllCategories">
            <div className="text-blue-500 py-1 px-3 rounded">
              View All
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CategoriesMenu2;
