import React from 'react'
import OrderCard from './card/OrderCard'

export default function ProfileMenu() {
  return (
    <div>
      <div className="flex gap-3">
      <OrderCard name="Order" orderNumber="12345" date="2024-09-12" amount="150.00" />
      <OrderCard name="Notification" orderNumber="12345" date="2024-09-12" amount="150.00" />
      <OrderCard name="Address" orderNumber="12345" date="2024-09-12" amount="150.00" />
      <OrderCard name="Favourit" orderNumber="12345" date="2024-09-12" amount="150.00" />
      <OrderCard name="Wallet" orderNumber="12345" date="2024-09-12" amount="150.00" />
      <OrderCard name="Transaction" orderNumber="12345" date="2024-09-12" amount="150.00" />
      <OrderCard name="Chat" orderNumber="12345" date="2024-09-12" amount="150.00" />
      <OrderCard name="Support" orderNumber="12345" date="2024-09-12" amount="150.00" />
      <OrderCard name="Refer and Earn" orderNumber="12345" date="2024-09-12" amount="150.00" />
      <OrderCard name="Logout" orderNumber="12345" date="2024-09-12" amount="150.00" />
    </div>
    </div>
  )
}
