import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../redux/slice/authSlice';
import postsReducer from './slice/postsSlice';
//import cartReducer from '../../redux/cartSlice'; // consumer slice
const store = configureStore({
  reducer: {
    auth: authReducer,
    posts: postsReducer,
   // cart: cartReducer,
  }
});
export default store;
