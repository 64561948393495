import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { url } from "../../components/api/Url";
import Banner3 from "../../components/cards/Banner3";
import UserContext from "../../context/UserContext";
import CategoriesMenu2 from "../../components/categaries_menu/CategoriesMenu2";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Card6 from "../../components/cards/Card6";
import BrandsSection2 from "../../components/brands/BrandsSection2";
import Highlights2 from "../../components/highlights/Highlights2";
import Features from "../../components/features/Features";
import Blogs2 from "../../components/blogs/Blogs2";
import Testimonials2 from "../../components/testimonials/Testimonials2";
import Model3D2 from "../../components/model3D/Model3D2";
import Video from "../../components/model3D/Video";
import { Link } from "react-router-dom";
import Cardb2b from "../../components/cards/Cardb2b";

const Sellerb2blist = () => {
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [sliderImages, setSliderImages] = useState([]);
  const [modelData, setModelData] = useState([]);
  const { setError, setLoader, loader, error } = useContext(UserContext);
  const navigate = useNavigate(); // For programmatic navigation

  const sellersUrl = url + "/app/v1/api/get_sellers";
  const baseUrl = url;

  async function fetchData() {
    try {
      const response = await axios.post(sellersUrl);
      setData(response.data.data || []);
    } catch (error) {
      console.error("Error fetching sellers:", error);
      setError(true);
    }
  }


  useEffect(() => {
    const fetchAllData = async () => {
      setLoader(true);
      await Promise.all([fetchData()]);
      setLoader(false);
    };
    fetchAllData();
  }, [setLoader, setError]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <>

      <div className="flex flex-row items-center justify-between mt-20 mb-5">
        <h1 className="font-bold text-4xl">Outlets Near Me</h1>
        <div>
       
        </div>
      </div>

      <Slider {...settings}>
        {data.map((value) => (
          <div className="px-2" key={value.seller_id}>
            <Cardb2b data={value} />
          </div>
        ))}
      </Slider>
    </>
  );
};

export default Sellerb2blist;
