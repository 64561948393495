import React from "react";
import { FaRegHeart } from "react-icons/fa6";

const ProductCard2 = ({ product }) => {
  // Helper function to round prices
  const roundPrice = (price) => Math.round(price);

  // Helper function to get the image URL
  const getImageUrl = (image) => {
    return image.startsWith('http') ? image : `https://zulushop.in/${image}`;
  };

  // Calculate discount percentage
  const calculateDiscount = (originalPrice, discountedPrice) => {
    if (originalPrice && discountedPrice) {
      return Math.round(((originalPrice - discountedPrice) / originalPrice) * 100);
    }
    return 0;
  };

  const discountPercentage = calculateDiscount(product.price, product.special_price);

  return (
    <div className="rounded-lg overflow-hidden mb-2 w-[250px]">
      <img
        src={getImageUrl(product.image)}
        alt={product.name}
        className="w-full h-64 object-cover rounded-2xl border"
      />
      <div className="p-4">
        <div className="flex justify-between items-center mb-2">
          <h3 className="font-semibold text-sm flex">
            {product.brand || product.name}
          </h3>
          <button className="text-gray-500 hover:text-red-500">
            <FaRegHeart />
          </button>
        </div>
        <p className="text-gray-700 text-xs text-left mb-2">{product.name}</p>
        <div className="flex items-center">
          <span className="mr-2">
            ₹{product.variants[0].price}
          </span>
          {discountPercentage > 0 && (
            <div className="flex items-center">
              <span className="text-gray-500 line-through text-sm mr-2">
                ₹{roundPrice(product.price)}
              </span>
              <span className="bg-green-500 text-white px-2 py-1 text-[10px] rounded">
                {discountPercentage}% OFF
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductCard2;