import React from "react";
import { Outlet } from "react-router-dom";
import UserContextProvider from "../../context/UserContextProvider";
import SellerHeader from "./Header/SellerHeader";
import SellerSuperTopBar from "./Header/SellerSuperTopBar";
import SellerFooter from "./Footer/SellerFooter";


function SellerLayout() {


  return (
    <>
      <UserContextProvider>
         <SellerSuperTopBar /> 
        <div className="container text-center mx-auto w-4/5 mt-36">
           <SellerHeader /> 
           <Outlet /> 
          <SellerFooter />
        </div>
      </UserContextProvider>
    </>
  );
}

export default SellerLayout;