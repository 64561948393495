import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles.css"; 
import { useNavigate } from "react-router-dom";

const Banner3 = ({ images , data }) => {
  const sliderRef = useRef(null); 
  const navigate = useNavigate()
console.log("object",data)
  const settings = {
    speed: 500,
    slidesToShow: 4, 
    slidesToScroll: 0.5,
    autoplay: true,
    autoplaySpeed: 2500,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const handleWheel = (e) => {
    if (Math.abs(e.deltaX) > Math.abs(e.deltaY)) { 
      e.preventDefault();
      if (e.deltaX < 0) {
        sliderRef.current.slickPrev(); 
      } else {
        sliderRef.current.slickNext(); 
      }
    }
  };

  const handleImageClick = (id) => {
    console.log(id,"img_id")
    navigate(`/image/${id}`);
  };

  return (
    <div className="text-left font-bold text-[22px] mt-5">
      <div>New Experiences</div>
      <div className="mt-5 w-[90%] mx-auto" onWheel={handleWheel}> 
        <Slider ref={sliderRef} {...settings}>
        
          {data.map((imageUrl, index) => (
            <div key={index} className=" flex gap-[10px] px-[10px]"onClick={() => handleImageClick(imageUrl?.id)}>
              <img
                src={`https://zulushop.in/${imageUrl?.image}`}
                className=" h-[300px] w-[300px] object-cover rounded-[20px]"
                alt={`Slide ${index + 1}`}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Banner3;
