import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Card6 from './Card6';
import CategoriesMenu3 from "../../components/categaries_menu/CategoriesMenu3";
import { CategoryProvider } from "../../components/categaries_menu/CategoryContext";
// import CategoriesMenu2 from "../../components/categaries_menu/CategoriesMenu2";
const ViewAllOutlets = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const [loader, setLoader] = useState(false);
  const sellersUrl = "https://zulushop.in/app/v1/api/get_sellers";

  useEffect(() => {
    const fetchAllOutlets = async () => {
      setLoader(true);  // Show loader while fetching data
      try {
        const response = await axios.post(sellersUrl);
        setData(response.data.data || []);  // Set data if available
      } catch (error) {
        console.error("Error fetching outlets:", error);
        setError(true);  // Handle errors
      } finally {
        setLoader(false);  // Stop loader after fetching
      }
    };

    fetchAllOutlets();  // Call the function to fetch data
  }, []);  // Empty dependency array to run this only once on component mount

  return (
    <div className="mt-20 mx-5">
          {/* Categories Section */}

  
      <div className="flex mt-5 flex-row items-center justify-between mb-5">
        <h1 className="font-bold text-4xl">Outlets Near Me</h1>
        <div>
          <Link to="/sellerhome" className="text-blue-700">
            Back
          </Link>
        </div>
      </div>

      {error && <p className="text-red-500">An error occurred. Please try again later.</p>}
      {loader && <p>Loading...</p>}

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4">
        {data.map((value) => (
          <div className="p-2" key={value.seller_id}>
            <Card6 data={value} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ViewAllOutlets;
