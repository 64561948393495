import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchPosts, setCurrentPage, deletePost } from '../../components/template_one/redux/slice/postsSlice';
//import { Button, IconButton } from "@material-tailwind/react";
//import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";
 
const PostsList = () => {
  const dispatch = useDispatch();
  const { posts, total, totalPages, currentPage, status } = useSelector((state) => state.posts);

  useEffect(() => {
    dispatch(fetchPosts(currentPage));
  }, [dispatch, currentPage]);

  const handlePageChange = (page) => {
    dispatch(setCurrentPage(page));
    dispatch(fetchPosts(page));
  };

  const handleDelete = (id) => {
    alert(id)
    dispatch(deletePost(id));
  };

  if (status === 'loading') return <p>Loading...</p>;
  if (status === 'failed') return <p>Error loading posts.</p>;

  return (
    <div>
      <h1>Posts</h1>
      <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              ID
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Title
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Content
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Action
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
        {posts?.map(post => (
          <tr key={post.id}>
              <td>{post.id}</td>
            <td>{post.title}</td>
            <td>{post.content}</td>
           <td> <button onClick={() => handleDelete(post.id)}>Delete</button></td>
          </tr>
        ))}
         </tbody>
      </table>
      <div className="flex items-center gap-4">
      <button
        variant="text"
        className="flex items-center gap-2"
      /*   onClick={prev}
        disabled={active === 1} */
      >
       {/*  <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" /> */} Previous
      </button>

        {Array.from({ length: totalPages }, (_, i) => (
          <button className="flex items-center gap-2"
            key={i + 1}
            onClick={() => handlePageChange(i + 1)}
            disabled={i + 1 === currentPage}
          >
            {i + 1}
          </button>
        ))}
          <button
        variant="text"
        className="flex items-center gap-2"
       /*  onClick={next} */
      /*   disabled={active === 5} */
      >
        Next
       {/*  <ArrowRightIcon strokeWidth={2} className="h-4 w-4" /> */}
      </button>
      </div>
    </div>
  );
};

export default PostsList;
