import React, { useEffect, useState } from "react";
import Card7 from "../cards/Card7";
import axios from "axios";
import { useCategory } from "./CategoryContext";

const CategoriesMenu3 = () => {
  const [categoriesData, setCategoriesData] = useState([]);
  const { setSelectedCategory } = useCategory();

  const getCategories = async () => {
    const categoriesUrl = "https://zulushop.in/zulu_category_list";

    try {
      const response = await axios.post(categoriesUrl);
      let res = response.data;

      console.log("Categories API response:", res);

      if (typeof res === "string") {
        try {
          res = JSON.parse(res);
        } catch (error) {
          console.error("Error parsing JSON response:", error);
          setCategoriesData([]);
          return;
        }
      }

      if (Array.isArray(res.data)) {
        // Filter to get only categories with children
        const categoriesWithChildren = res.data.filter(
          (category) => category.children && category.children.length > 0
        );

        const categories = categoriesWithChildren.map((category) => ({
          zulu_category_name: category.name,
          zulu_category_image: `${category.image}`,
          zulu_category_id: category.id,
          children: category.children,
        }));

        console.log("Filtered categories with children:", categories);
        setCategoriesData(categories);
      } else {
        console.warn("Unexpected data structure:", res);
        setCategoriesData([]);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
      setCategoriesData([]);
    }
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <div className="mt-6">
      <div className="mt-1">
        {/* On small screens, use flex for horizontal scrolling. On larger screens, revert to grid layout */}
        <div className="flex overflow-x-auto space-x-4 md:grid md:grid-cols-3 md:gap-4 lg:grid-cols-5 w-full sm:w-[80%] md:w-[70%] lg:w-[60%] mx-auto">
          {categoriesData.length > 0 ? (
            categoriesData.map((category) => (
              <div
                key={category.zulu_category_id}
                onClick={() => handleCategoryClick(category)}
                className="flex-shrink-0 w-[150px] sm:w-auto"
              >
                <Card7
                  className="p-1"
                  zulu_category_name={category.zulu_category_name}
                  zulu_category_image={category.zulu_category_image}
                  zulu_category_id={category.zulu_category_id}
                />
              </div>
            ))
          ) : (
            <p>No categories to display</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CategoriesMenu3;
