import React, { useState } from 'react';

const AnotherBannerForm = ({ bannerId, onSubmit, onClose }) => {
  const [formData, setFormData] = useState({
    whereFrom: 'Anywhere',
    brands: [],
    totalBudget: { min: '', max: '' },
    products: [{ category: '', subCategory: '', subSubCategory: '' }],
    inspiration: '',
    inspirationImage: null,
    budgetRange: { min: '', max: '' },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleProductChange = (index, field, value) => {
    const updatedProducts = [...formData.products];
    updatedProducts[index][field] = value;
    setFormData((prevState) => ({
      ...prevState,
      products: updatedProducts,
    }));
  };

  const handleAddProduct = () => {
    if (formData.products.length < 5) {
      setFormData((prevState) => ({
        ...prevState,
        products: [...prevState.products, { category: '', subCategory: '', subSubCategory: '' }],
      }));
    }
  };

  const handleRemoveProduct = (index) => {
    const updatedProducts = formData.products.filter((_, i) => i !== index);
    setFormData((prevState) => ({
      ...prevState,
      products: updatedProducts,
    }));
  };

  const handleImageChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      inspirationImage: e.target.files[0],
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <div className="max-h-[90vh] overflow-y-auto  "> 
      <form onSubmit={handleSubmit} className="space-y-4 ">
        {bannerId === '5' && (
          <>
            <h2 className="text-2xl font-bold mb-4">Instant Fashion</h2>

            <div>
              <label htmlFor="whereFrom" className="block text-sm font-medium text-gray-700">Where from</label>
              <select
                id="whereFrom"
                name="whereFrom"
                value={formData.whereFrom}
                onChange={handleChange}
                className="mt-1 block md:w-[600px] lg:w-[600px] rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              >
                <option>Anywhere</option>
                <option>Zara</option>
                <option>Malls</option>
                <option>Ethnic</option>
                <option>Boutique</option>
                <option>Delhi Wintage</option>
              </select>
            </div>

            <div>
              <label htmlFor="totalBudgetMin" className="block text-sm font-medium text-gray-700">Total Budget (Min)</label>
              <input
                type="number"
                id="totalBudgetMin"
                name="totalBudgetMin"
                value={formData.totalBudget.min}
                onChange={(e) => handleChange(e, 'min')}
                className="mt-1 block md:w-[600px] lg:w-[600px] rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                placeholder="Min Rs"
              />
              <label htmlFor="totalBudgetMax" className="block text-sm font-medium text-gray-700">Total Budget (Max)</label>
              <input
                type="number"
                id="totalBudgetMax"
                name="totalBudgetMax"
                value={formData.totalBudget.max}
                onChange={(e) => handleChange(e, 'max')}
                className="mt-1 block md:w-[600px] lg:w-[600px] rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                placeholder="Max Rs"
              />
            </div>

            <div>
              <h3 className="text-lg font-bold">Add Products</h3>
              {formData.products.map((product, index) => (
                <div key={index} className="space-y-2">
                  <div>
                    <label htmlFor={`category-${index}`} className="block text-sm font-medium text-gray-700">Category</label>
                    <select
                      id={`category-${index}`}
                      name={`category-${index}`}
                      value={product.category}
                      onChange={(e) => handleProductChange(index, 'category', e.target.value)}
                      className="mt-1 block md:w-[600px] lg:w-[600px] rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    >
                      <option>Men</option>
                      <option>Women</option>
                      <option>Kids</option>
                      <option>Home</option>
                    </select>
                  </div>
                  <div>
                    <label htmlFor={`subCategory-${index}`} className="block text-sm font-medium text-gray-700">Sub Category</label>
                    <input
                      type="text"
                      id={`subCategory-${index}`}
                      name={`subCategory-${index}`}
                      value={product.subCategory}
                      onChange={(e) => handleProductChange(index, 'subCategory', e.target.value)}
                      className="mt-1 block md:w-[600px] lg:w-[600px] rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                  <div>
                    <label htmlFor={`subSubCategory-${index}`} className="block text-sm font-medium text-gray-700">Sub Sub Category</label>
                    <input
                      type="text"
                      id={`subSubCategory-${index}`}
                      name={`subSubCategory-${index}`}
                      value={product.subSubCategory}
                      onChange={(e) => handleProductChange(index, 'subSubCategory', e.target.value)}
                      className="mt-1 block md:w-[600px] lg:w-[600px] rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                  {index > 0 && (
                    <button type="button" onClick={() => handleRemoveProduct(index)} className="text-red-500 text-sm">
                      Remove Product
                    </button>
                  )}
                </div>
              ))}
              {formData.products.length < 5 && (
                <button
                  type="button"
                  onClick={handleAddProduct}
                  className="mt-2 py-1 px-4 bg-black text-white rounded-md shadow-sm"
                >
                  Add Another Product
                </button>
              )}
            </div>

            <div>
              <label htmlFor="inspiration" className="block text-sm font-medium text-gray-700">Inspiration</label>
              <textarea
                id="inspiration"
                name="inspiration"
                value={formData.inspiration}
                onChange={handleChange}
                className="mt-1 block md:w-[600px] lg:w-[600px] rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>

            <div>
              <label htmlFor="inspirationImage" className="block text-sm font-medium text-gray-700">Inspiration Image</label>
              <input
                type="file"
                id="inspirationImage"
                name="inspirationImage"
                onChange={handleImageChange}
                className="mt-1 block md:w-[600px] lg:w-[600px] rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>

            <div>
              <label htmlFor="budgetRangeMin" className="block text-sm font-medium text-gray-700">Budget Range (Min)</label>
              <input
                type="number"
                id="budgetRangeMin"
                name="budgetRangeMin"
                value={formData.budgetRange.min}
                onChange={(e) => handleChange(e, 'budgetRangeMin')}
                className="mt-1 block md:w-[600px] lg:w-[600px] rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                placeholder="Min Rs"
              />
              <label htmlFor="budgetRangeMax" className="block text-sm font-medium text-gray-700">Budget Range (Max)</label>
              <input
                type="number"
                id="budgetRangeMax"
                name="budgetRangeMax"
                value={formData.budgetRange.max}
                onChange={(e) => handleChange(e, 'budgetRangeMax')}
                className="mt-1 block md:w-[600px] lg:w-[600px] rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                placeholder="Max Rs"
              />
            </div>
          </>
        )}

        <div>
          <button
            type="submit"
            className="md:w-[600px] lg:w-[600px] py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-stone-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Submit and Proceed
          </button>
        </div>
      </form>
    </div>
  );
};

export default AnotherBannerForm;
