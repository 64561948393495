import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import ProductCard from "../cards/ProductCard";
import { useProduct } from "./ProductContext";
import FilterDropdown from "./FilterDropdown";
import { FaSliders } from "react-icons/fa6";
import axios from "axios";

const AllProductCategory = () => {
  const { selectProduct } = useProduct();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialBrand = queryParams.get('brand') || "";
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState(initialBrand);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedPriceRange, setSelectedPriceRange] = useState("");
  const [sortOption, setSortOption] = useState("");
  const [allproducts, setAllProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [priceRanges, setPriceRanges] = useState([]);
  
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 16;

  const getProducts = async () => {
    const url = "https://zulushop.in/app/v1/api/get_products";
    try {
      const formData = new FormData();
      formData.append("limit", 1000);
      formData.append("offset", 0);

      const response = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log(response.data);
      setPriceRanges([{ min: response.data.min_price, max: response.data.max_price }]);
      setAllProducts(response.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    let updatedProducts = allproducts;

    if (selectedBrand) {
      updatedProducts = updatedProducts.filter(
        (product) => product.brand === selectedBrand
      );
    }

    if (selectedCategory) {
      updatedProducts = updatedProducts.filter(
        (product) => product.category_name === selectedCategory
      );
    }

    if (selectedPriceRange) {
      const [min, max] = selectedPriceRange.split("-").map(Number);
      updatedProducts = updatedProducts.filter((product) => {
        const price = product.min_max_price.special_price;
        return price >= min && price <= max;
      });
    }

    if (sortOption === "price-high") {
      updatedProducts.sort((a, b) => {
        const aprice = a.variants[0].special_price || a.variants[0].price;
        const bprice = b.variants[0].special_price || b.variants[0].price;
        return aprice - bprice;
      });
    } else if (sortOption === "price-low") {
      updatedProducts.sort((a, b) => {
        const aprice = a.variants[0].special_price || a.variants[0].price;
        const bprice = b.variants[0].special_price || b.variants[0].price;
        return bprice - aprice;
      });
    }

    setFilteredProducts(updatedProducts);
    setCurrentPage(1); // Reset to first page when filters change

 
    // Update URL with current filters
    const params = new URLSearchParams();
    if (selectedBrand) params.set('brand', selectedBrand);
    if (selectedCategory) params.set('category', selectedCategory);
    if (selectedPriceRange) params.set('priceRange', selectedPriceRange);
    if (sortOption) params.set('sort', sortOption);
    navigate(`?${params.toString()}`, { replace: true });

  }, [selectedBrand, selectedCategory, selectedPriceRange, sortOption, allproducts, navigate]);

  const handleRemoveFilter = (filter) => {
    if (filter === "brand") setSelectedBrand("");
    if (filter === "category") setSelectedCategory("");
    if (filter === "priceRange") setSelectedPriceRange("");
  };

  const handleClearAllFilters = () => {
    setSelectedBrand("");
    setSelectedCategory("");
    setSelectedPriceRange("");
    setSortOption("");
    navigate("", { replace: true });
  };

  const getUniqueBrands = () => {
    const brands = allproducts.map((product) => product.brand);
    return [...new Set(brands)]; // Remove duplicates
  };

  const getUniqueCategories = () => {
    const categories = allproducts.map((product) => product.category_name);
    return [...new Set(categories)]; // Remove duplicates
  };

  const filterOptions = {
    brand: getUniqueBrands().slice(1),
    category: getUniqueCategories().slice(1),
    priceRanges,
  };

  // Pagination logic
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);
  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

    // Toggle filter visibility
    const toggleFilterVisibility = () => {
      setIsFilterVisible(!isFilterVisible);
    };
  

  const getPageNumbers = () => {
    const pageNumbers = [];
    let startPage = Math.max(1, currentPage - 1);
    let endPage = Math.min(totalPages, currentPage + 2);

    if (currentPage <= 2) {
      endPage = Math.min(4, totalPages);
    }

    if (currentPage > totalPages - 2) {
      startPage = Math.max(1, totalPages - 3);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  if (loading) return <div className="text-center py-8">Loading...</div>;
  if (error) return <div className="text-center py-8 text-red-500">{error.message}</div>;

  return (
    <div className="mx-[auto] py-8">
      <div className="flex flex-col md:flex-row">
           {/* Mobile Filter Toggle Button */}
           <div className="md:hidden mb-4">
          <button
            className="w-full bg-gray-200 py-2 px-4 rounded-lg flex items-center justify-center"
            onClick={toggleFilterVisibility}
          >
            <FaSliders className="mr-2" />
            {isFilterVisible ? "Hide Filters" : "Show Filters"}
          </button>
        </div>

      
        {/* Left Sidebar for Filters */}
        <div className={`w-full md:w-[20%] pr-8 mb-4 md:mb-0 ${isFilterVisible ? 'block' : 'hidden md:block'}`}>
          <div className="font-semibold text-lg mb-4 flex items-center border-b-2">
            <div className="flex items-center mb-2">
              <div>
                <FaSliders />
              </div>
              <div className="ml-3"> Filters</div>
            </div>
          </div>

          <FilterDropdown
            title="Brand"
            options={filterOptions.brand}
            selectedOption={selectedBrand}
            setSelectedOption={setSelectedBrand}
          />
          <FilterDropdown
            title="Category"
            options={filterOptions.category}
            selectedOption={selectedCategory}
            setSelectedOption={setSelectedCategory}
          />
          <FilterDropdown
            title="Price Range"
            options={filterOptions.priceRanges.map(
              (range) => `${range.min}-${range.max}`
            )}
            selectedOption={selectedPriceRange}
            setSelectedOption={setSelectedPriceRange}
          />
        </div>

        {/* Right Section for Products */}
        <div className="w-full md:w-3/4">
          {/* Active Filters Section */}
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xs">{filteredProducts.length} items</h2>
            <div className="flex items-center space-x-4">
              <div>
                {selectedBrand && (
                  <span className="bg-gray-200 px-2 py-1 rounded-lg text-sm mr-2">
                    {selectedBrand}
                    <button
                      className="ml-2 text-red-500"
                      onClick={() => handleRemoveFilter("brand")}
                    >
                      &times;
                    </button>
                  </span>
                )}
                {selectedCategory && (
                  <span className="bg-gray-200 px-2 py-1 rounded-lg text-sm mr-2">
                    {selectedCategory}
                    <button
                      className="ml-2 text-red-500"
                      onClick={() => handleRemoveFilter("category")}
                    >
                      &times;
                    </button>
                  </span>
                )}
                {selectedPriceRange && (
                  <span className="bg-gray-200 px-2 py-1 rounded-lg text-sm mr-2">
                    Price: {selectedPriceRange}
                    <button
                      className="ml-2 text-red-500"
                      onClick={() => handleRemoveFilter("priceRange")}
                    >
                      &times;
                    </button>
                  </span>
                )}
                {(selectedBrand || selectedCategory || selectedPriceRange) && (
                  <button
                    className="ml-2 text-blue-500 "
                    onClick={handleClearAllFilters}
                  >
                    Clear All
                  </button>
                )}
              </div>

              {/* Sort Options */}
              <select
                className="border rounded-md p-2"
                value={sortOption}
                onChange={(e) => setSortOption(e.target.value)}
              >
                <option value="default">Sort by</option>
                <option value="price-low">Price: Low to High</option>
                <option value="price-high">Price: High to Low</option>
              </select>
            </div>
          </div>

          {/* Product Grid */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:mx-[-30px] lg:mx-[-30px] mx-auto  md:grid-cols-3 lg:grid-cols-4 gap-6">
            {currentProducts.length > 0 ? (
              currentProducts.map((product) => (
                <Link
                  key={product.id}
                  to={`/productdetail/${product.id}`}
                  onClick={() => selectProduct(product)}
                 className="md:ml-5 md:mx-2 lg:mx-2  mx-auto"
                >
                  <ProductCard product={product} />
                </Link>
              ))
            ) : (
              <div>No products found.</div>
            )}
          </div>

          {/* Pagination Controls */}
          <div className="mt-4 flex justify-center items-center space-x-2">
            <button
              className="text-black px-4 py-2 rounded-lg"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              &lt;
            </button>
            {getPageNumbers().map((pageNumber) => (
              <button
                key={pageNumber}
                className={`px-4 py-2 rounded-lg ${currentPage === pageNumber ? 'underline' : 'text-blue-500'}`}
                onClick={() => setCurrentPage(pageNumber)}
              >
                {pageNumber}
              </button>
            ))}
            <button
              className=" text-black px-4 py-2 rounded-lg"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              &gt;
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllProductCategory;