import React, { useState } from "react";
import  {FaCaretDown} from "react-icons/fa6";

const LocationDropdown = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState("Select Location");

  const locations = [
    "M3M Merlin",
    "Pioneer Park",
    "Ireo Skyon",
    "Ireo Uptown",
    "Emerald Hills",
    "Ireo Corridor",
    "Palm Drive",
    "Bestech Parkview",
    "Pioneer Araya",
    "BPTP Park Prime",
    "Dhoot Apartments",
  ];

  const handleLocationClick = (location) => {
    setSelectedLocation(location);
    setShowDropdown(false);
  };

  return (
    <div className="relative w-full">
      {/* LOCATION DROPDOWN */}
      <div
        className="w-full flex ml-2 text-sm text-white items-center cursor-pointer"
        onClick={() => setShowDropdown(!showDropdown)}
      >
        Delivering To
        <FaCaretDown className="ml-1" />
      </div>

      {showDropdown && (
        <ul className="absolute mt-2 w-full bg-white text-black shadow-lg rounded-lg">
          {locations.map((location, index) => (
            <li
              key={index}
              className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
              onClick={() => handleLocationClick(location)}
            >
              {location}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LocationDropdown;
