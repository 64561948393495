import React, { useState } from 'react';
import axios from 'axios';
import { FaShoppingBag ,FaRegHeart } from "react-icons/fa";

function LinkInput() {
  const [url, setUrl] = useState('');
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
    
      const response = await axios.get(`${url}`);
      setData(response.data);
      setLoading(false);
    } catch (err) {
        window.location.reload();
        setError('Failed to fetch data from the URL');
    }
  };

  return (
    <div className="container mx-auto p-4">
    
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          className="border p-2 w-full"
          placeholder="Enter the URL"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 mt-2"
        >
          Fetch Details
        </button>
      </form>

      {loading && <p className='text-center'>Loading...</p>}
      {error && <p className="text-red-500">{error}</p>}

      {data ? (
        <div className="flex flex-wrap gap-3 justify-center sm:justify-normal sm:flex-nowrap items-center  mt-4">
          {data.image ? (
            <img
              src={data.image}
              className="w-[300px] object-contain h-[300px]"
              alt="Product"
            />
          ) : (
            <p>No image available</p>
          )}

          <div className="details ml-4">
            <div className="flex flex-col gap-5">
              {data.category && (
                <h3 className="text-3xl font-semibold uppercase">
                  {data.category}
                </h3>
              )}
              {data.title && <h1>{data.title}</h1>}
              {data.price && (
                <h2 className="text-slate-500 font-bold">
                  MRP :- <span className="text-black">₹ {data.price}</span>
                </h2>
              )}
              {data.description && <p>{data.description}</p>}
            </div>

            {(data.title || data.price || data.description) && (
              <div className="flex gap-3 flex-wrap items-center mt-3">
                <button className="p-4 bg-pink-500 flex uppercase font-bold  text-white items-center gap-3"> <FaShoppingBag/> Add To Bag</button>
                <button className="p-4 border border-black uppercase font-bold  flex items-center gap-3"><FaRegHeart/> Wish List</button>
              </div>
            )}
          </div>
        </div>
      ) : (
        !loading && <p></p> 
      )}
    </div>
  );
}

export default LinkInput;
