import React, { useState, useMemo } from 'react';
import { Edit2, Trash2, PlusCircle } from 'lucide-react';
import { toast } from 'react-toastify';
import { useCart } from './CartContext';
import { FaHome } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';

const CheckoutPage = () => {
    const location = useLocation();
  const { cartItems, removeFromCart } = useCart();
  const [promoCode, setPromoCode] = useState('');
  const [selectedAddress, setSelectedAddress] = useState(0);
  const [addresses, setAddresses] = useState([
    {
      name: 'Cozy Nest - Home',
      address: '123 Maple Street, Gurgaon',
      state: 'Haryana',
      country: 'India',
      zip: '122018',
      phone: '9999999999',        
    }
  ]);
  const [isEditingAddress, setIsEditingAddress] = useState(false);
  const [isAddingNewAddress, setIsAddingNewAddress] = useState(false);
  const [newAddress, setNewAddress] = useState({
    name: '',
    address: '',
    state: '',
    country: '',
    zip: '',
    phone: '',
  });
  const [useWallet, setUseWallet] = useState(false);
  const [walletBalance, setWalletBalance] = useState(1000000);
  const [paymentMethod, setPaymentMethod] = useState('');

  const cartProducts = location.state?.cartProducts || cartItems;

  const subtotal = useMemo(() => {
    return cartProducts.reduce((total, item) => total + item.min_max_price.special_price * item.quantity, 0);
  }, [cartProducts]);

  const deliveryCharge = 70.00;
  const total = subtotal + deliveryCharge;

  const handleEditAddress = () => {
    setIsEditingAddress(true);
  };

  const handleSaveAddress = () => {
    setIsEditingAddress(false);
    toast.success("Address updated successfully!");
  };

  const handleAddNewAddress = () => {
    setIsAddingNewAddress(true);
  };

  const handleSaveNewAddress = () => {
    setAddresses([...addresses, newAddress]);
    setSelectedAddress(addresses.length);
    setIsAddingNewAddress(false);
    setNewAddress({
      name: '',
      address: '',
      state: '',
      country: '',
      zip: '',
      phone: '',
    });
    toast.success("New address added successfully!");
  };

  const handleRemoveItem = (id) => {
    removeFromCart(id);
  };

  // Handle mutually exclusive selection of payment methods
  const handleWalletChange = () => {
    setUseWallet(!useWallet);
    if (!useWallet) {
      setPaymentMethod('wallet');
    } else {
      setPaymentMethod('');
    }
  };

  const handleCODChange = () => {
    if (paymentMethod === 'cod') {
      setPaymentMethod(''); // Deselect COD
    } else {
      setUseWallet(false); // Disable wallet when COD is selected
      setPaymentMethod('cod');
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-6">Checkout</h1>
      <div className="flex flex-col lg:flex-row gap-8">
        <div className="lg:w-2/3">
          <div className="bg-white p-6 rounded-lg shadow-md mb-6">
            <h2 className="text-xl font-semibold mb-4">Billing Details</h2>
            {/* Add billing details fields here */}
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md mb-6">
            <div className="flex justify-between text-left items-center mb-4">
              <h2 className="text-xl font-semibold items-center flex">   <FaHome className='mr-4' />Shipping Address</h2>
           
              <div>
                <button onClick={handleEditAddress} className="text-blue-500 hover:text-blue-700 mr-2">
                  <Edit2 size={18} />
                </button>
                <button onClick={handleAddNewAddress} className="text-green-500 hover:text-green-700">
                  <PlusCircle size={18} />
                </button>
              </div>
            </div>
            {isEditingAddress ? (
              <>
                <input
                  type="text"
                  value={addresses[selectedAddress].name}
                  onChange={(e) => setAddresses(addresses.map((addr, index) => 
                    index === selectedAddress ? {...addr, name: e.target.value} : addr
                  ))}
                  className="w-full mb-2 px-3 py-2 border rounded-lg"
                />
                <input
                  type="text"
                  value={addresses[selectedAddress].address}
                  onChange={(e) => setAddresses(addresses.map((addr, index) => 
                    index === selectedAddress ? {...addr, address: e.target.value} : addr
                  ))}
                  className="w-full mb-2 px-3 py-2 border rounded-lg"
                />
                <input
                  type="text"
                  value={addresses[selectedAddress].state}
                  onChange={(e) => setAddresses(addresses.map((addr, index) => 
                    index === selectedAddress ? {...addr, state: e.target.value} : addr
                  ))}
                  className="w-full mb-2 px-3 py-2 border rounded-lg"
                />
                <input
                  type="text"
                  value={addresses[selectedAddress].country}
                  onChange={(e) => setAddresses(addresses.map((addr, index) => 
                    index === selectedAddress ? {...addr, country: e.target.value} : addr
                  ))}
                  className="w-full mb-2 px-3 py-2 border rounded-lg"
                />
                <input
                  type="text"
                  value={addresses[selectedAddress].zip}
                  onChange={(e) => setAddresses(addresses.map((addr, index) => 
                    index === selectedAddress ? {...addr, zip: e.target.value} : addr
                  ))}
                  className="w-full mb-2 px-3 py-2 border rounded-lg"
                />
                <input
                  type="text"
                  value={addresses[selectedAddress].phone}
                  onChange={(e) => setAddresses(addresses.map((addr, index) => 
                    index === selectedAddress ? {...addr, phone: e.target.value} : addr
                  ))}
                  className="w-full mb-2 px-3 py-2 border rounded-lg"
                />
                <button onClick={handleSaveAddress} className="bg-blue-500 text-white px-4 py-2 rounded-lg">
                  Save Address
                </button>
              </>
            ) : isAddingNewAddress ? (
              <>
                <input
                  type="text"
                  placeholder="Name"
                  value={newAddress.name}
                  onChange={(e) => setNewAddress({...newAddress, name: e.target.value})}
                  className="w-full mb-2 px-3 py-2 border rounded-lg"
                />
                <input
                  type="text"
                  placeholder="Address"
                  value={newAddress.address}
                  onChange={(e) => setNewAddress({...newAddress, address: e.target.value})}
                  className="w-full mb-2 px-3 py-2 border rounded-lg"
                />
                <input
                  type="text"
                  placeholder="State"
                  value={newAddress.state}
                  onChange={(e) => setNewAddress({...newAddress, state: e.target.value})}
                  className="w-full mb-2 px-3 py-2 border rounded-lg"
                />
                <input
                  type="text"
                  placeholder="Country"
                  value={newAddress.country}
                  onChange={(e) => setNewAddress({...newAddress, country: e.target.value})}
                  className="w-full mb-2 px-3 py-2 border rounded-lg"
                />
                <input
                  type="text"
                  placeholder="ZIP Code"
                  value={newAddress.zip}
                  onChange={(e) => setNewAddress({...newAddress, zip: e.target.value})}
                  className="w-full mb-2 px-3 py-2 border rounded-lg"
                />
                <input
                  type="text"
                  placeholder="Phone"
                  value={newAddress.phone}
                  onChange={(e) => setNewAddress({...newAddress, phone: e.target.value})}
                  className="w-full mb-2 px-3 py-2 border rounded-lg"
                />
                <button onClick={handleSaveNewAddress} className="bg-green-500 text-white px-4 py-2 rounded-lg">
                  Add New Address
                </button>
              </>
            ) : (
              <div className='text-left text-sm'>
                <p>{addresses[selectedAddress].name}</p>
                <p>{addresses[selectedAddress].address}</p>
                <p>{addresses[selectedAddress].state}, {addresses[selectedAddress].country} - {addresses[selectedAddress].zip}</p>
                <p>{addresses[selectedAddress].phone}</p>
              </div>
            )}
            <p className="mt-4 text-left text-sm text-gray-500">*All the products are deliverable to the selected address.</p>
          </div>
          
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-4">Payment Method</h2>
            <div className="flex items-center mb-4">
              <input
                type="checkbox"
                id="use-wallet"
                checked={useWallet}
                onChange={handleWalletChange}
                className="mr-2"
              />
              <label htmlFor="use-wallet" className="cursor-pointer">Use Wallet Balance (Available Balance: {walletBalance})</label>
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                id="cod"
                checked={paymentMethod === 'cod'}
                onChange={handleCODChange}
                className="mr-2"
              />
              <label htmlFor="cod" className="cursor-pointer">Cash on Delivery (COD)</label>
            </div>
          </div>
        </div>

        <div className="lg:w-1/3">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-4">Order Summary</h2>
            <ul>
              {cartProducts.map((item) => (
                <li key={`${item.id}-${item.size}`} className="mb-4">
                  <div className="flex justify-between">
                    <img  src={item.image}
        alt={item.name}
        className="w-24 mr-4 lg:w-24 h-24 object-cover rounded-lg lg:mr-4 mb-4 lg:mb-0"/>
                    <span>{item.name} (x{item.quantity})</span>
                    <span>₹{item.min_max_price.special_price * item.quantity}</span>
                  </div>
                </li>
              ))}
            </ul>
            <div className="text-left">
              <div className="mb-2">
                <p className="font-medium">Subtotal:</p>
                <p className="text-right">${subtotal.toFixed(2)}</p>
              </div>
              <div className="mb-2">
                <p className="font-medium">Delivery Charges:</p>
                <p className="text-right">${deliveryCharge.toFixed(2)}</p>
              </div>
              <div className="mb-2">
                <p className="font-medium">Total:</p>
                <p className="text-right font-bold">${total.toFixed(2)}</p>
              </div>
            </div>
            <button className="bg-green-500 text-white w-full py-2 rounded-lg mt-4">Place Order</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutPage;
