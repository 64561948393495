import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Heart, Share2 } from 'lucide-react';
import { FaCheckCircle, FaCreditCard, FaShoppingBag } from "react-icons/fa";
import { useProduct } from "./ProductContext";
import { FaTentArrowTurnLeft } from "react-icons/fa6";
import ProductCard from "../cards/ProductCard";
import { useCart } from './CartContext'; 

const ProductDetailPage = () => {
  const { productId } = useParams();
  const { products, loading, error, selectedProduct, selectProduct } = useProduct();
  const [selectedSize, setSelectedSize] = useState(null);
  const predefinedSizes = ["S", "M", "L", "XL", "XXL"];
  const [activeTab, setActiveTab] = useState("specification");
  const { addToCart } = useCart();

  const isSizeAvailable = (size) => {
    return selectedProduct?.variants?.some(variant => variant.size === size && variant.stock > 0) ?? false;
  };

  useEffect(() => {
    if (products.length > 0) {
      const product = products.find(p => p.id === parseInt(productId, 10));
      if (product) {
        selectProduct(product);
      }
    } else {
      const storedProducts = JSON.parse(localStorage.getItem('products') || '[]');
      const product = storedProducts.find(p => p.id === parseInt(productId, 10));
      if (product) {
        selectProduct(product);
      }
    }
  }, [products, productId, selectProduct]);

  // Recommended Products based on brand or price range
  const recommendedProducts = products.filter(
    p => 
      p.brand === selectedProduct?.brand && p.id !== selectedProduct?.id ||
      (p.min_max_price.special_price >= selectedProduct?.min_max_price.special_price * 0.8 && 
      p.min_max_price.special_price <= selectedProduct?.min_max_price.special_price * 1.2)
  );

  // Helper function to round prices
  const roundPrice = (price) => Math.round(price);

  // Calculate Discount
  const calculateDiscount = (price, specialPrice) => {
    return Math.round(((price - specialPrice) / price) * 100);
  };

  if (loading) {
    return <div className="text-center py-8">Loading...</div>;
  }
  if (error) {
    return <div className="text-center py-8 text-red-500">{error.message}</div>;
  }
  if (!selectedProduct) {
    return <div className="text-center py-8">Product not found</div>;
  }

  const variant = selectedProduct.variants?.[0]; // Assuming you're using the first variant for now

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex flex-col md:flex-row gap-8">
        {/* Image Gallery */}
        <div className="w-full md:w-[60%] space-y-4">
  {/* Large Image */}
  <div className="w-full mx-auto">
    {selectedProduct.other_images && selectedProduct.other_images.length > 0 ? (
      <img
        src={selectedProduct.other_images[0]} 
        alt={`${selectedProduct.name || 'N/A'} - view 1`}
        className="w-full h-auto md:w-[440px] md:h-[400px] object-cover"
      />
    ) : (
      <img
        src={selectedProduct.image || '/path/to/default-image.png'}
        alt={selectedProduct.name || 'N/A'}
        className="w-full h-auto md:w-[440px] md:h-[400px] object-cover"
      />
    )}
  </div>

  {/* Small Images */}
  <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-4">
    {selectedProduct.other_images && selectedProduct.other_images.length > 1 ? (
      selectedProduct.other_images.slice(1).map((image, index) => (
        <img
          key={index}
          src={image}
          alt={`${selectedProduct.name || 'N/A'} - view ${index + 2}`}
          className="w-full h-[150px] sm:h-[200px] object-cover"
        />
      ))
    ) : null}
  </div>
</div>



        {/* Product Details */}
        <div className="md:w-1/3">
          <div className="flex justify-between items-center">
            <h1 className="text-2xl font-bold">{selectedProduct.name || 'N/A'}</h1>
            <div className="flex gap-2">
              <Share2 className="w-6 h-6" />
              <Heart className="w-6 h-6" />
            </div>
          </div>

          <div className="flex items-center gap-4 text-left mb-4">
            <span className="text-2xl font-bold">₹{roundPrice(variant?.special_price || variant?.price || 'N/A')}</span>
            {variant?.price !== variant?.special_price && (
              <>
                <span className="text-gray-500 line-through">MRP: ₹{roundPrice(variant?.price)}</span>
                <span className="bg-green-500 text-white px-2 py-1 text-sm rounded">
                  {calculateDiscount(variant?.price, variant?.special_price)}% OFF
                </span>
              </>
            )}
          </div>

          {/* Additional Product Information */}
          <div className="text-left font-semibold">
            <p className="mb-4">Store: {selectedProduct.store_name || 'N/A'}</p>
            <p className="mb-4">Seller Rating: {selectedProduct.seller_rating || 'N/A'}</p>
            <p className="mb-4">Color: {selectedProduct.color || 'N/A'}</p>
          </div>

          {/* Size Selection */}
          <div className="mb-4 text-left font-semibold">
            <p className="">Size:</p>
            <div className="flex gap-2">
              {predefinedSizes.map(size => (
                <button
                  key={size}
                  className={`w-16 h-16 border ${selectedSize === size ? 'bg-black text-white' : 'bg-white text-black'} ${isSizeAvailable(size) ? '' : 'opacity-50 cursor-not-allowed'}`}
                  onClick={() => isSizeAvailable(size) && setSelectedSize(size)}
                  disabled={!isSizeAvailable(size)}
                >
                  {size}
                </button>
              ))}
              <button
                className={`w-16 h-16 border ${selectedSize === null ? 'bg-black text-white' : 'bg-white text-black'}`}
                onClick={() => setSelectedSize(null)}
              >
                Any
              </button>
            </div>
          </div>

          <div className="flex gap-4 mb-8">
            <button className="px-10 py-4 border border-black text-black font-semibold rounded-xl">Buy Now</button>
            <button 
              className="px-10 py-4 bg-black text-white font-semibold rounded-xl"
              onClick={() => {
                addToCart(selectedProduct, selectedSize);
                // alert("Product added to bag!");
              }}
            >
              Add to Cart
            </button>
          </div>
          
          <div className="grid grid-cols-2 gap-4 mb-8">
            <div className="text-center flex">
              <FaCreditCard className="mx-2 " />
              <p className="text-sm">Secure Payments</p>
            </div>
            <div className="text-center flex">
              <FaCheckCircle className="mx-2 " />
              <p className="text-sm">Genuine Product</p>
            </div>
            <div className="text-center flex">
              <FaShoppingBag className="mx-2 " />
              <p className="text-sm">Try & Buy</p>
            </div>
            <div className="text-center flex">
              <FaTentArrowTurnLeft className="mx-2 " />
              <p className="text-sm">7 Day Return</p>
            </div>
          </div>

          {/* SPECIFICATIONS */}
          <div className="border rounded-xl p-4 text-left h-[320px]">
            <div className="flex justify-center space-x-8 border-gray-300">
              <button
                className={`text-lg font-semibold pb-2 ${activeTab === "specification" ? "border-b-2 border-black" : "text-gray-500"}`}
                onClick={() => setActiveTab("specification")}
              >
                Specifications
              </button>
              <button
                className={`text-lg font-semibold pb-2 ${activeTab === "description" ? "border-b-2 border-black" : "text-gray-500"}`}
                onClick={() => setActiveTab("description")}
              >
                Description
              </button>
            </div>

            {/* Tab Content */}
            <div className="mt-6">
              {activeTab === "specification" && (
                <div className="space-y-4">
                  {/* Specification Details */}
                  <p className="font-semibold">Category: {selectedProduct.category || "N/A"}</p>
                  <p className="font-semibold">Size: {selectedProduct.size || "N/A"}</p>
                  <p className="font-semibold">Color: {selectedProduct.color || "N/A"}</p>
                  <p className="font-semibold">Brand: {selectedProduct.brand || "N/A"}</p>
                  <p className="font-semibold">Stock: {selectedProduct.stock > 0 ? 'In Stock' : 'Out of Stock'}</p>
                </div>
              )}
              {activeTab === "description" && (
                <p>{selectedProduct.description || "No description available."}</p>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* RECOMMENDED PRODUCTS */}
      <div className="mt-8">
        <h2 className="text-2xl font-bold mb-4">Recommended Products</h2>
        <div className="flex flex-wrap justify-center gap-4">
          {recommendedProducts.map(product => (
            // <ProductCard key={product.id} product={product} />
            <Link 
            key={product.id}
            to={`/productdetail/${product.id}`}
            onClick={() => selectProduct(product)}
            className=""
          >
            <ProductCard product={product} />
          </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductDetailPage;
