import React from "react";
import { FaRegHeart } from "react-icons/fa6";

const ProductCard = ({ product }) => {
  // Helper function to round prices
  const roundPrice = (price) => Math.round(price);

  return (
    <div className=" rounded-lg  overflow-hidden mb-2  md:w-[250px] w-[250px] lg:w-[250px]">
      <img
        src={product.image}
        alt={product.name}
        className="w-full h-64 object-cover rounded-2xl border"
      />
      <div className="p-4">
        <div className="flex justify-between items-center mb-2">
          <h3 className="font-semibold text-sm flex">
            {product.brand || product.name}
          </h3>
          <button className="text-gray-500 hover:text-red-500">
            <FaRegHeart />
          </button>
        </div>
        <p className="text-gray-700 text-xs text-left mb-2">{product.name}</p>
        <div className="flex items-center">
          <span className="mr-2">
            ₹{roundPrice(product.variants[0].special_price || product.variants[0].price)}
          </span>
          {product.min_max_price.discount_in_percentage > 0 && (
            <div className="flex items-center">
              <span className="text-gray-500 line-through text-sm mr-2">
                ₹{roundPrice(product.variants[0].price)}
              </span>
              <span className="bg-green-500 text-white px-2 py-1 text-[10px] rounded">
                {roundPrice(product.min_max_price.discount_in_percentage)}% OFF
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
