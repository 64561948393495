import React from "react";
import { url } from "../api/Url";

const Card2 = ({ zulu_category_name, seller_category_name, zulu_category_image, seller_category_image, zulu_category_id }) => {
  return (
    <div className="relative w-[150px] cursor-pointer mb-4">
      {
        seller_category_image ?
          <img src={`${seller_category_image}`} alt="" className="w-full h-[180px] object-cover bg-gray-200 rounded-lg" />
          :
          <img src={`${zulu_category_image}`} alt="" className="w-full h-[180px] object-cover bg-gray-200 rounded-lg" />
      }
      
      <div className="absolute bottom-0 w-full bg-black bg-opacity-60 text-white text-center py-1 rounded-b-lg">
        <p className="text-sm">
          {seller_category_name ? seller_category_name : zulu_category_name}
        </p>
      </div>
    </div>
  );
};

export default Card2;
