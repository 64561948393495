import React, { useState, useEffect, useRef } from "react";
import "./HeaderMain.css";
import logo from "../../../assets/images/zulu-logo-3.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { IoMdMenu, IoMdClose } from "react-icons/io";
import { FaHome, FaShoppingBag, FaBlog, FaInfoCircle, FaUser, FaFireAlt, FaRegUser, FaShoppingCart, FaStoreAlt } from "react-icons/fa";
import { FaHome as FaHomeOutline, FaShoppingBag as FaShoppingBagOutline, FaBlog as FaBlogOutline, FaInfoCircle as FaInfoCircleOutline, FaUser as FaUserOutline } from "react-icons/fa";
import Popup from "../Popup";
import { FaCircleInfo, FaCompass, FaRegCompass } from "react-icons/fa6";
import { useDispatch } from 'react-redux';
import loginUser from "../../template_one/redux/slice/authSlice";
import { url } from "../../api/Url";
import LocationDropdown from "./LocationDropdown"; // Adjust the import path as needed
import ShoppingCart from "./ShoppingCart";
import { useCart } from "../../categaries_menu/CartContext";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ClipLoader } from 'react-spinners';
import Spinner from "../../Spinner/Spinner";
import ShoppingCartWindow from "./ShoppingCartWindow";


const HeaderNew = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [mobile, setMobile] = useState('');
  const [otp, setOtp] = useState('');
  const [isloading, setIsloading] = useState('');
  const [errorMessage, setErrorMessage] = useState("")
  const [islogin, setIslogin] = useState(true)
  const [isotpscreen, setIsotpscreen] = useState(false)
  const [timer, setTimer] = useState(120); // 60 seconds timer
  const [isResendEnabled, setIsResendEnabled] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const { cartItems } = useCart();
  const cartItemCount = cartItems.reduce((total, item) => total + item.quantity, 0);


  const menuRef = useRef(null);
  const [isCartSidebarOpen, setIsCartSidebarOpen] = useState(false);

  const toggleCartSidebar = () => {
    setIsCartSidebarOpen((prev) => !prev);
  };
  
  const [isModalOpen, setModalOpen] = useState(false);
  const openModal = () => {

    setModalOpen(true);
  }

  const closeModal = () => setModalOpen(false);
  
  
  const toggleMenu = () => {
    menuRef.current.classList?.toggle('show__menu');
    setIsMenuOpen(prevState => !prevState);
  };

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;

    if (prevScrollPos > currentScrollPos) {
      setVisible(true);
    } else {
      setVisible(false);
    }

    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Check if user data exists in localStorage
    const userData = localStorage.getItem('mobileno');
   // alert(userData);
    if (userData) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }


    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  const handleOpenPopup = () => {
    setIsPopupVisible(true);
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false);
  };
  const manual_login_for_development = async () => {
    setIsLoggedIn(false)
    setIsloading(true)
    //return
    const datafrom = new FormData();
    datafrom.append('mobile', '9879192804'); //localStorage.getItem('tem_mobileno') localStorage.getItem('tem_mobileno')
    datafrom.append('password', '12345678');
    datafrom.append('type', 'phone');
    console.log(datafrom);
    try {
      const response = await fetch(url + '/app/v1/api/sign_up_with_mobile', {
        method: 'POST',
        body: datafrom
      });

      if (!response.ok) {
       
        toast('Network response was not ok')
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (data.message == "mobile no already exits") {
        let st = localStorage.getItem('tem_mobileno');
        localStorage.setItem('mobileno', st);
        setIsloading(false)
        isModalOpen(false)
        toast('Login Successfully')
       window.location.href = '/o';
        
      }
      else if (data.message == "Registered Successfully") {
        let st = localStorage.getItem('tem_mobileno');
     
        localStorage.setItem('mobileno', st);
        setIsloading(false)
     
        isModalOpen(false)
        toast('Login Successfully')
       window.location.href = '/o';
      }
      else if(data.message== "ALREADY_VERIFIED")
      {
        toast('Already Verified')
        setIsloading(false)
        window.location.href = '/o';
      }
      console.log('Success:', data);
    } catch (error) {
      toast(error)
      window.location.href = '/o';
      setIsloading(false)
    }
  setIsloading(false)

  }
  const handleSubmitotp_test = async () => {
    setIsLoggedIn(false)
    setIsloading(true)
    //return
    const datafrom = new FormData();
    datafrom.append('mobile', localStorage.getItem('tem_mobileno')); //localStorage.getItem('tem_mobileno') localStorage.getItem('tem_mobileno')
    datafrom.append('password', '12345678');
    datafrom.append('type', 'phone');
    console.log(datafrom);
    try {
      const response = await fetch(url + '/app/v1/api/sign_up_with_mobile', {
        method: 'POST',
        body: datafrom
      });

      if (!response.ok) {
       
        toast('Network response was not ok')
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (data.message == "mobile no already exits") {
        let st = localStorage.getItem('tem_mobileno');
        localStorage.setItem('mobileno', st);
        setIsloading(false)
        isModalOpen(false)
        toast('Login Successfully')
       window.location.href = '/o';
        
      }
      else if (data.message == "Registered Successfully") {
        let st = localStorage.getItem('tem_mobileno');
     
        localStorage.setItem('mobileno', st);
        setIsloading(false)
     
        isModalOpen(false)
        toast('Login Successfully')
       window.location.href = '/o';
      }
      else if(data.message== "ALREADY_VERIFIED")
      {
        toast('Already Verified')
        setIsloading(false)
        window.location.href = '/o';
      }
      console.log('Success:', data);
    } catch (error) {
      toast(error)
      window.location.href = '/o';
      setIsloading(false)
    }
  setIsloading(false)

  }

  const handleSubmitotp = async (e) => {
    e.preventDefault();
    // alert(otp);
    setIsloading(true)
    if (otp == "") {
     toast('Insert the OTP')
     setIsloading(false)
    }
    else { //`/results?${queryParams}`
      let verification = localStorage.getItem('verificationId');
      let sendurl = `https://cpaas.messagecentral.com/verification/v3/validateOtp?verificationId=${verification}&code=${otp}`;

      fetch(sendurl, { ///api/login
        // method: 'GET',
        headers: {
          'authToken': `${localStorage.getItem('token')}`,
        },

      })
        .then((response) => response.json())
        .then((response) => {
          setIsloading(false);
          console.log('verification response' + JSON.stringify(response));
          if (response.message == "SUCCESS" && response.data.verificationStatus == "VERIFICATION_COMPLETED") {
            //alert('verified');
            //start checking to insert into database
            handleSubmitotp_test();

            //end db checking
          }
          /* {"responseCode":505,"message":"verificationId is invalid","data":null} */
          else if (response.message == "verificationId is invalid") {
            // alert('Invalid SMS Code');
            toast("Invalid, Invalid SMS Code!");
          }
        })
        .catch((err) => {
          console.log(err.message);
        });

    }

  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsloading(true);
    const response = await fetch('https://cpaas.messagecentral.com/auth/v1/authentication/token?customerId=C-659CA72D61C94C9&key=QWRhcnNoMTIzIQ==&scope=NEW&country=91&email=adarsh.bhatia@gmail.com', {
    });

    const data = await response.json();
    let token = data.token;
    localStorage.setItem('token', token);
    fetch("https://cpaas.messagecentral.com/verification/v3/send?countryCode=91&flowType=SMS&mobileNumber=" + mobile, { ///api/login
      method: 'POST',
      headers: {
        'authToken': `${token}`,
      },
      body: ''
    })
      .then((response) => response.json())
      .then((response) => {
        setIsloading(false);
        console.log('sms' + JSON.stringify(response));
        if (response.message == "SUCCESS") {
          localStorage.setItem("verificationId", response.data.verificationId);
          localStorage.setItem("tem_mobileno", mobile);
          // let code = response.verificationId;
          console.log('verification id : ' + response.data.verificationId);
          setIslogin(false);
          setIsotpscreen(true);

        }
      })
      .catch((err) => {
        console.log(err.message);
      });


  };
 
  useEffect(() => {
    let interval = null;

    if (timer > 0 && !isResendEnabled) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsResendEnabled(true);
    }

    return () => clearInterval(interval);
  }, [timer, isResendEnabled]);

  const handleResendOtp = () => {

  }
  const logOut = async () => {
    localStorage.clear();
    window.location.href = '/o';
  }

//   const toggleCart = () => {
//     if (isLoggedIn) {
//       setShowCart(!showCart);
//     } else {
//       openModal();
//     }
//   };
// //testopt
  const testopt = async() => {

 
  };
  return (
    <div>
      <header
        id="header"
        className={`fixed top-0 px-[7.5%] w-full h-20 right-0 z-10 flex items-center justify-between bg-[#1f0a1dae] transition-transform duration-500 ${visible ? "top-0" : "-top-20"}`}
      >
        <div className="flex items-center justify-between w-full">
          <div className="w-[400px] flex items-center">
            <a href="/sellerhome" className="logo w-16">
              <img src={logo} className="w-full h-full" alt="ZULU Logo" />
            </a>
            {/* LOCATION DROPDOWN */}
            <div className=" w-full flex ml-2 text-lg text-white">        <LocationDropdown /></div>


          </div>
          <nav
            id="navbar"
            className="hidden lg:flex items-center space-x-8 text-sm uppercase"
            ref={menuRef}
          >

            <NavLink
              to="/sellerhome"
              className={({ isActive }) =>
                `flex items-center ${isActive ? "text-[#ffde59]" : "text-white"}`
              }
            >
              <FaRegCompass className="mr-2" />
              Explore
            </NavLink>

            <NavLink
              to="/allproducts"
              className={({ isActive }) =>
                `flex items-center ${isActive ? "text-[#ffde59]" : "text-white"}`
              }
            >
              <FaShoppingBagOutline className="mr-2" />
              Shop now
            </NavLink>


            <NavLink
              to="/alloutlets"
              className={({ isActive }) =>
                `flex items-center ${isActive ? "text-[#ffde59]" : "text-white"}`
              }
            >
              <FaStoreAlt className="mr-2" />
              Outlets
            </NavLink>

            <NavLink
              to="/image/5"
              className={({ isActive }) =>
                `flex items-center ${isActive ? "text-[#ffde59]" : "text-white"}`
              }
            >
              <FaHomeOutline className="mr-2" />
              At Home
            </NavLink>

            <NavLink
              to="/"
              className={({ isActive }) =>
                `flex items-center ${isActive ? "text-[#ffde59]" : "text-white"}`
              }
            >
              {/* <FaHomeOutline className="mr-2" /> */}
              Zulu
            </NavLink>


            <NavLink
        to="#"
        onClick={toggleCartSidebar}
        className="flex items-center text-white"
      >
        <FaShoppingCart className="mr-2 w-6 h-6" />
        Cart {cartItemCount > 0 && `(${cartItemCount})`}
      </NavLink>

            {
              isLoggedIn ?
                <>
                  <NavLink to="/profile-page"

                    className={({ isActive }) =>
                      `flex items-center ${isActive ? "text-[#ffde59]" : "text-white"}`
                    }
                  >
                    <FaRegUser className="mr-2" />
                    Profile
                  </NavLink>
                  <NavLink to="#"
                    onClick={logOut}
                    className={({ isActive }) =>
                      `flex items-center ${isActive ? "text-[#ffde59]" : "text-white"}`
                    }
                  >
                    <FaRegUser className="mr-2" />
                    LogOut
                  </NavLink>
                </>
                :
                <>
                  <NavLink to="#"
                    onClick={openModal}
                    className={({ isActive }) =>
                      `flex items-center ${isActive ? "text-[#ffde59]" : "text-white"}`
                    }
                  >
                    <FaRegUser className="mr-2" />
                    Profile
                  </NavLink>
                </>
            }


          </nav>
          <div className="lg:hidden flex items-center" onClick={toggleMenu}>
            {isMenuOpen ? <IoMdClose className="text-xl" /> : <IoMdMenu className="text-xl" />}
          </div>
        </div>
      </header>

      <Popup isVisible={isPopupVisible} onClose={handleClosePopup} />
      {/* Cart Sidebar Component */}
      <div>
      <ShoppingCartWindow
        isOpen={isCartSidebarOpen}
        onClose={toggleCartSidebar}
      />
    </div>
   
  
      {/*  <!-- modal --> */}
      {
        isModalOpen ?
          <>
            <div
              className="fixed grid place-items-center backdrop-blur-sm top-0 right-0 left-0 z-50 w-full inset-0 h-modal h-full justify-center items-center">
              <div className="relative container m-auto px-6">
                <div className="m-auto md:w-7/12">
                  <div className="rounded-xl bg-white dark:bg-gray-800 shadow-xl">
                    <div className="p-8">
                      <div className="space-y-4">
                        <div className="flex flex-grow justify-between s">
                          <img src="https://www.svgrepo.com/show/475643/dribbble-color.svg" loading="lazy"
                            className="w-10" />
                          <Link to="#" onClick={closeModal}>close</Link>
                        </div>
                          <button onClick={handleSubmitotp_test}>Manual Login</button> 
                        <h2 className="mb-8 text-2xl text-cyan-900 dark:text-white font-bold">
                          Signup
                        </h2>
                        <ToastContainer />
                      {/*   {
                          isloading ? <Spinner size="20" />
                          :
                          <button onClick={testopt}>Test </button>
                        } */}
                      
                      </div>
                      <div className="mt-10 grid space-y-4">
                        {
                          islogin ?
                            <>
                              <form onSubmit={handleSubmit} className="max-w-sm mx-auto">
                                <label for="phone-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone number:</label>
                                <div className="relative">
                                  <div className="absolute inset-y-0 start-0 top-0 flex items-center ps-3.5 pointer-events-none">
                                    <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 19 18">
                                      <path d="M18 13.446a3.02 3.02 0 0 0-.946-1.985l-1.4-1.4a3.054 3.054 0 0 0-4.218 0l-.7.7a.983.983 0 0 1-1.39 0l-2.1-2.1a.983.983 0 0 1 0-1.389l.7-.7a2.98 2.98 0 0 0 0-4.217l-1.4-1.4a2.824 2.824 0 0 0-4.218 0c-3.619 3.619-3 8.229 1.752 12.979C6.785 16.639 9.45 18 11.912 18a7.175 7.175 0 0 0 5.139-2.325A2.9 2.9 0 0 0 18 13.446Z" />
                                    </svg>
                                  </div>
                                  <input type="text" id="phone-input"
                                    aria-describedby="helper-text-explanation"
                                    value={mobile}
                                    onChange={(e) => setMobile(e.target.value)}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 
                      text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 
                      block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600
                       dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500
                        dark:focus:border-blue-500" /* pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" */
                                    placeholder="Mobile No" required />
                                </div>
                                <p id="helper-text-explanation" className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                                  Select a phone number that matches the format.
                                </p>
                                {
                                  isloading ?
                                    <Spinner />
                                    :
                                    <>
                                      <button
                                        className="group h-12 px-6 border-2 border-gray-300 rounded-full transition duration-300 hover:border-blue-400 focus:bg-blue-50 active:bg-blue-100">
                                        <div className="relative flex items-center space-x-4 justify-center">

                                          <span
                                            class="block w-max font-semibold tracking-wide text-gray-700 dark:text-white text-sm transition duration-300 group-hover:text-blue-600 sm:text-base">

                                            Submit
                                          </span>
                                        </div>
                                      </button>
                                    </>
                                }

                              </form>

                            </>
                            :
                            <>
                              <form onSubmit={handleSubmitotp} className="max-w-sm mx-auto">
                                <label for="phone-input"
                                  className="block mb-2 text-sm font-medium text-gray-900
                                 dark:text-white">OTP

                                </label>
                                <div className="relative">
                                  <div className="absolute inset-y-0 start-0 top-0 flex items-center ps-3.5 pointer-events-none">
                                    <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 19 18">
                                      <path d="M18 13.446a3.02 3.02 0 0 0-.946-1.985l-1.4-1.4a3.054 3.054 0 0 0-4.218 0l-.7.7a.983.983 0 0 1-1.39 0l-2.1-2.1a.983.983 0 0 1 0-1.389l.7-.7a2.98 2.98 0 0 0 0-4.217l-1.4-1.4a2.824 2.824 0 0 0-4.218 0c-3.619 3.619-3 8.229 1.752 12.979C6.785 16.639 9.45 18 11.912 18a7.175 7.175 0 0 0 5.139-2.325A2.9 2.9 0 0 0 18 13.446Z" />
                                    </svg>
                                  </div>
                                  <input type="text" id="phone-input"
                                    aria-describedby="helper-text-explanation"
                                    value={otp}
                                    onChange={(e) => setOtp(e.target.value)}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 
                      text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 
                      block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600
                       dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500
                        dark:focus:border-blue-500" /* pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" */
                                    placeholder="OTP here" required />
                                </div>
                                <p id="helper-text-explanation" className="mt-2 text-sm text-gray-500 dark:text-gray-400">Select a phone number that matches the format.</p>
                                {
                                  isloading ?
                                    <Spinner />
                                    :
                                    <button
                                      className="group h-12 px-6 border-2 border-gray-300 rounded-full transition duration-300
                                       hover:border-blue-400 focus:bg-blue-50 active:bg-blue-100" style={{ marginTop: 20  }}>
                                      <div className="relative flex items-center space-x-4 justify-center">
                                        <span
                                          class="block w-max font-semibold tracking-wide text-gray-700 dark:text-white text-sm transition duration-300 group-hover:text-blue-600 sm:text-base">
                                          Verify OTP
                                        </span>
                                      </div>
                                    </button>
                                }
                              </form>
                              <div className="mt-2">
                                {timer > 0 ? (
                                  <p>Resend OTP in {timer} seconds</p>
                                ) : (
                                  <button
                                    onClick={handleResendOtp}
                                    disabled={!isResendEnabled}
                                    className={`bg-blue-500 text-white p-2 rounded ${!isResendEnabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                                  >
                                    Resend OTP
                                  </button>
                                )}
                              </div>

                            </>
                        }

                      </div>
                      <div className="mt-14 space-y-4 py-3 text-gray-600 dark:text-gray-400 text-center">
                        <p className="text-xs">By proceeding, you agree to our
                          <a href="/privacy-policy/" className="underline">Terms of Use</a>
                          and confirm you have read our
                          <a href="/privacy-policy/" className="underline">Privacy and Cookie Statement</a>.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </>
          :
          ''
      }




    </div>
  );
};

export default HeaderNew;
