import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { url } from "../api/Url";
import Threedmodel from "../cards/Threedmodel";

const Model3D = ({ data, sellerid }) => {
  const [threedmodel, setThreemodel] = useState([]);
  const getThreemodel = async () => {
    const json = JSON.stringify({ seller_id: sellerid, video_type: '' });
    axios
      .post(url + "/get_all_videos", json)
      .then((res) => {
   //   console.log('..three model...' + res.data.message);
      setThreemodel(res.data.message.slice(0, 4));
      })
      .catch(() => {
        setThreemodel([]);
      });
  }; 
  useEffect(() => {
    getThreemodel();
  }, []);


  return (
    <div className="mt-10">
      <div className="flex justify-between items-center">
        <h1 className="text-left font-bold text-[22px]" style={{ color: `${data?.component?.image_gallery?.color}` }}>3D Models</h1>
        <Link to="" className="text-blue-700">
          view all
        </Link>
      </div>
      <div className="flex justify-center md:justify-between flex-wrap items-center">
                {threedmodel && threedmodel.map((product) => (
                  // {item.product_details.productinfo.map((product) => (
                  <div key={product.id}>
                    <Threedmodel
                      video_name={product.video_name}
                    
                    />
                  </div>
                ))}
              </div>
      
    </div>
  );
};

export default Model3D;
