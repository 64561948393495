import React from "react";

const Banner2 = () => {
  return (
    <div className={`rounded-xl overflow-hidden bg-slate-200 w-[65%]`}>
      <img
        src="https://zulushop.in/uploads/seller/Frame_1300941.png"
        className={`w-full  bg-slate-500 object-cover`}
        alt=""
      />
    </div>
  );
};

export default Banner2;
